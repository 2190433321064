/*
==========================
||     HPRO ATENÇÃO     ||
==========================

---------------------------------------
Criado em 05/06/2020 por IP
Atualizações:
    -
---------------------------------------

- PROPRIEDADES:
    - animation: Se ele só aparece no meio ou tem uma animação deslizando
    - animationdirection: Se for ter animação, pra qual lado ele desliza

    **-buttons: 1 ou 2, se passado 1 ele funciona como um atenção do HPro com apenas 1 botão que chama a função "onSim"
    se passado 2, ele fica como um diálogo de confirmação, tem as opções para passar os captions dos botões (agreeName/disagreeName)
    e cada um responde a sua respectiva função (onSim/onNao).

- Exemplo de como usar:
const [rmdialog, setRmdialog] = useState(false)

.
. Código
.

<Hproatencao
    title="Confirma a exclusão do usuário?" -> qlqr título q desejar
    subtitle="qlqr coisa" -> padrão vazio
    isOpen={rmdialog} -> state do formulário que ta usando
    onSim={handleRmDialog} -> função no formulário que ta usando
    onNao={setRmdialog} -> função do state do formulário que ta usando
    animation={true / false   = padrao false}
    animationdirection="up / down / left / right" -> padrão 'up'
    agreeName="nome do botão equivalente ao aceitar" -> padrão 'Sim'
    disagreeName="nome do botão equivalente ao não aceitar" -> padrão 'Não'
    buttons={1 ou 2} 
/>    

*/
import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Fade from '@material-ui/core/Fade'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide ref={ref} {...props} />
})

const FadeStyle = React.forwardRef(function FadeStyle(props, ref) {
    return <Fade ref={ref} {...props} />
})

const Hproatencao = ({
    isOpen,
    title,
    subtitle = '',
    onSim,
    onNao,
    animation = false,
    animationdirection = 'up',
    agreeName = 'Sim',
    disagreeName = 'Não',
    buttons = 1 }) => {

    const botao = (
        (buttons === 1)
            ?
            <Button onClick={() => onSim()} color="primary" autoFocus>
                {agreeName}
            </Button>
            :
            <>
                <Button onClick={() => onSim()} color="primary">
                    {agreeName}
                </Button>
                <Button onClick={() => onNao()} color="primary" autoFocus>
                    {disagreeName}
                </Button>
            </>
    )

    return (
        <div>
            <Dialog
                open={isOpen}
                TransitionProps={{ direction: animationdirection }}
                TransitionComponent={animation ? Transition : FadeStyle}
                keepMounted
                onClose={() => onNao()}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {subtitle}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {botao}
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Hproatencao