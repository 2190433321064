export function formatValor(valor) {    
    let aux = ''
    let dec = ''
    let nJ  = 0
    let nI  = 0
    let val = valor.toString()
        val = val.replace('.',',')
    let res = val.split(',')
    if (res.length === 1) {
        for (nI = res[0].length; nI > 0; nI--) {
            aux = res[0][nI-1] + aux
            nJ = nJ + 1
            if (nJ === 3 && nI-1 > 0) {
                aux = '.'+aux
                nJ = 0
            }
        }
        if (!aux) {
            aux = '0'
        }
        aux = aux+',00'
        return aux
    } else if (res.length === 2) {
        for (nI = res[0].length; nI > 0; nI--) {
            aux = res[0][nI-1] + aux
            nJ = nJ + 1
            if (nJ === 3 && nI-1 > 0) {
                aux = '.'+aux
                nJ = 0
            }
        }
        nJ = 0
        for (nI = res[1].length; nI > 0; nI--) {
            if (nJ < 2 && nI < 3) {
                dec = res[1][nI-1] + dec
                nJ = nJ + 1            
            }
        }
        if (nJ === 1){
            dec = dec+'0'
        }
        return aux+','+dec
    } else {            
        return '0,00'
        //alert('Valor preenchido incorretamente')
    }
}

export function ctov(valor) {   
    if (!valor) {
        valor = '0,00'
    }
    let cAux = valor
    while (cAux.indexOf('.') > -1) {
        cAux = cAux.replace('.', "") 
    }
    return parseFloat(cAux.replace(",", "."))
}

export function format(str, size, char = '0') {
    let cAux = str.toString()
    for (let nI = str.toString().length ; nI < size; nI++) {
        cAux = char + cAux
    }
    return cAux
}