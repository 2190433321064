import React, { useState, useEffect, useCallback } from 'react'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Fade, Fab, Tooltip, Typography, TextField } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

import { formatValor, ctov, format } from '../../components/Rotinas'

const Fin01 = ({ open, setOpen, handleCheck, itens, favCount, tax, ted, ven, setVen, fpa, setFpa, emails, setEmails }) => {

	const useStyles = makeStyles((theme) => ({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		modalRoot: {
			backgroundColor: theme.palette.background.paper,
			borderRadius: '7px',
			boxShadow: theme.shadows[5],
			height: '90%',
			minWidth: 1000,
			flex: (midea680) ? 1 : 0,
			display: 'flex',
			flexDirection: 'column',
		},
		title: {
			marginBottom: 15,
		},
		item: {
			marginBottom: 10,
		},
	}))

	const classes = useStyles()
	const midea680 = useMediaQuery('(max-width:680px)')
	const [total, setTotal] = useState('0,00')
	const [tottax, setTottax] = useState('0,00')
	const [totted, setTotted] = useState('0,00')
	const [totfat, setTotfat] = useState('0,00')

	const getInfos = useCallback(() => {
		let nVal = 0
		itens.map(x => nVal = nVal + ctov(x.val))

		const favSelecionados = itens.filter((iten) => Boolean(ctov(iten.val)))

		setTotal(formatValor(nVal))
		setTottax(`${formatValor(nVal * (ctov(tax) / 100))} (Taxa administrativa de ${tax} %)`)
		setTotted(`${formatValor((ctov(ted) * favSelecionados.length))}  (Tarifa de R$ ${ted} p/ premiado)`)
		setTotfat(formatValor(nVal * (1 + ctov(tax) / 100) + (ctov(ted) * favSelecionados.length)))

		setFpa('')

		let cDat = new Date()
		setVen(`${cDat.getFullYear()}-${format(cDat.getMonth() + 1, 2)}-${format(cDat.getDate(), 2)}`)
	}, [itens, ted, tax, setFpa, setVen])

	useEffect(() => {
		getInfos()
	}, [open, getInfos])

	return (
		<Modal
			style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
			open={open}
			onClose={() => setOpen(false)}
		>
			<Fade in={open}>
				<div className={classes.modalRoot}>
					<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#00a099', borderRadius: 7 }}>
						<Typography variant="h5" style={{ color: 'white', alignSelf: 'center', marginTop: 4, marginBottom: 4 }}>Finalização do carregamento</Typography>
					</div>
					<div style={{ flex: 1, overflowY: 'scroll', padding: 15, display: 'flex', flexDirection: 'column' }}>
						<Typography variant="h4" className={classes.title}>Resumo de valores</Typography>
						<Typography variant="h6" className={classes.item}>- Valor total de premiação: R$ {total}</Typography>
						<Typography variant="h6" className={classes.item}>- Valor taxa de administração: R$ {tottax}</Typography>
						{Boolean(ctov(ted)) && <Typography variant="h6" className={classes.item}>- Valor taxa de TED: R$ {totted}</Typography>}
						<Typography variant="h6" className={classes.item}>- Valor total da NF/Fatura: R$ {totfat}</Typography>

						<Typography variant="h4" className={classes.title}>Forma de pagamento</Typography>
						<div style={{ display: 'flex', padding: 15 }}>
							<div
								onClick={() => setFpa('TED')}
								style={{
									flex: 1,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
									marginRight: 15,
									backgroundColor: (fpa === 'TED') ? 'blue' : 'white',
									borderRadius: 15,
									height: 80,
								}}
							>
								<Typography variant="h4">TED</Typography>
							</div>
							<div
								onClick={() => setFpa('Boleto')}
								style={{
									flex: 1,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
									marginLeft: 15,
									backgroundColor: (fpa === 'Boleto') ? 'blue' : 'white',
									borderRadius: 15,
									height: 80,
								}}
							>
								<Typography variant="h4">Boleto</Typography>
							</div>
						</div>
						<div style={{ marginTop: 12, display: 'flex', flexDirection: 'column' }}>
							<TextField
								label='E-mails - separados por ";"'
								variant="outlined"
								value={emails}
								onChange={(e) => setEmails(e.target.value)}
								size="small"
								title='E-mails - separados por ";" (ponto e vírgula)'
								style={{ width: '100%', marginRight: 15, marginBottom: 15 }}
							/>
							<TextField
								label="Vencimento do boleto"
								variant="outlined"
								value={ven}
								onChange={(e) => setVen(e.target.value)}
								size="small"
								style={{ width: 300 }}
								disabled={(fpa !== 'Boleto')}
								type="date"
							/>
						</div>
					</div>
					<div style={{ display: 'flex' }}>
						<Tooltip style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} title={'Finalizar'} placement="top-start">
							<Fab
								color="primary"
								aria-label="Finalizar"
								size="small"
								type="button"
								onClick={() => handleCheck()}
							>
								<CheckIcon />
							</Fab>
						</Tooltip>
						<Tooltip style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} title={'Fechar'} placement="top-start">
							<Fab
								color="primary"
								aria-label="Fechar"
								size="small"
								type="button"
								onClick={() => setOpen(false)}
							>
								<CloseIcon />
							</Fab>
						</Tooltip>
					</div>
				</div>
			</Fade>
		</Modal >
	)
}

export default Fin01
