import React, { useState, useEffect } from 'react'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Fade, Fab, Tooltip, Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

import { form, delayedAlert } from '../../components/HproUtils'

const Pla01 = ({ open, setOpen, select, setMsg, cli, pro }) => {

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalRoot: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '7px',
            boxShadow: theme.shadows[5],
            height: '70%',
            minWidth: 1000,
            flex: (midea680) ? 1 : 0,
            display: 'flex',
            flexDirection: 'column',
        },
    }))

    const classes = useStyles()
    const midea680 = useMediaQuery('(max-width:680px)')

    const [pla, setPla] = useState(false)
    const [plaSel, setPlaSel] = useState('')

    useEffect(() => {
        setPla(false)
        setPlaSel('')
    }, [open])

    const handleCheck = async () => {
        if (pla) {

            if (!plaSel) {
                setMsg({
                    open: true,
                    type: 'error',
                    text: 'É necessário selecionar a planilha'
                })
                return
            }

            try {

                const fd = new FormData()
                fd.append('pla', plaSel, plaSel.name)
                fd.append('nom', localStorage.getItem('TouchWeb_UserName'))
                fd.append('cli', cli)
                fd.append('pro', pro)

                const processResponse = await form('IMPORTAPLANILHA', fd)
                const dataJson = await JSON.parse(processResponse)

                if (dataJson.status === 'success') {
                    select()
                } else {
                    setMsg({
                        open: true,
                        type: 'error',
                        text: dataJson.message
                    })
                }
            } catch (error) {
                delayedAlert('Error', error.message)
                console.log(error)
            }
        } else {
            select()
        }
    }

    const handleSelect = (e) => {
        e.persist()
        setPlaSel(e.target.files[0])
    }

    return (
        <Modal
            style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            open={open}
            onClose={() => setOpen(false)}
        >
            <Fade in={open}>
                <div className={classes.modalRoot}>
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#00a099', borderRadius: 7 }}>
                        <Typography variant="h5" style={{ color: 'white', alignSelf: 'center', marginTop: 4, marginBottom: 4 }}>Escolha o formato de inclusão do carregamento</Typography>
                    </div>
                    <div style={{ flex: 1, overflowY: 'scroll' }}>
                        <div style={{ display: 'flex', padding: 15 }}>
                            <div
                                onClick={() => setPla(true)}
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                                    marginRight: 15,
                                    backgroundColor: (pla) ? '#00a099' : 'white',
                                    borderRadius: 15,
                                    height: 80,
                                    cursor: 'pointer'
                                }}
                            >
                                <Typography variant="h4" style={{ color: (pla) ? 'white' : '#00a099' }}>Planilha</Typography>
                            </div>
                            <div
                                onClick={() => setPla(false)}
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                                    marginLeft: 15,
                                    backgroundColor: (pla) ? 'white' : '#00a099',
                                    borderRadius: 15,
                                    height: 80,
                                    cursor: 'pointer'
                                }}
                            >
                                <Typography variant="h4" style={{ color: (pla) ? '#00a099' : 'white' }}>Manual</Typography>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <label
                                for="arquivo"
                                style={{
                                    padding: '20px 10px',
                                    width: 'max-content',
                                    backgroundColor: pla ? '#00a099' : '#00a09955',
                                    color: '#FFF',
                                    textTransform: 'uppercase',
                                    textAlign: 'center',
                                    display: 'block',
                                    borderRadius: 15,
                                    marginTop: 20,
                                    marginLeft: 15,
                                    cursor: 'pointer'
                                }}
                            >{plaSel.name ? 'Arquivo selecionado: ' + plaSel.name : 'Enviar arquivo'}</label>
                            <input type="file" accept=".xlsx,.xls" id="arquivo" onChange={(e) => handleSelect(e)}
                                style={{
                                    display: 'none'
                                }}
                                disabled={!pla} />
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Tooltip style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} title={'Finalizar'} placement="top-start">
                            <Fab
                                color="primary"
                                aria-label="Finalizar"
                                size="small"
                                type="button"
                                onClick={() => handleCheck()}
                            >
                                <CheckIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} title={'Fechar'} placement="top-start">
                            <Fab
                                color="primary"
                                aria-label="Fechar"
                                size="small"
                                type="button"
                                onClick={() => setOpen(false)}
                            >
                                <CloseIcon />
                            </Fab>
                        </Tooltip>
                    </div>
                </div>
            </Fade>
        </Modal >
    )
}

export default Pla01
