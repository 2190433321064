import React from 'react'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Fade, Fab, Tooltip, Typography, ButtonBase } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CloseIcon from '@material-ui/icons/Close'

const Pro01 = ({ open, setOpen, select, cliente, itens, cliTit }) => {

	const useStyles = makeStyles((theme) => ({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		modalRoot: {
			backgroundColor: theme.palette.background.paper,
			borderRadius: '7px',
			boxShadow: theme.shadows[5],
			//padding: theme.spacing(2, 4, 3),
			height: '70%',
			minWidth: 1000,
			flex: (midea680) ? 1 : 0,
			display: 'flex',
			flexDirection: 'column',
		},
	}))

	const classes = useStyles()
	const midea680 = useMediaQuery('(max-width:680px)')

	return (
		<Modal
			style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
			open={open}
			onClose={() => setOpen(false)}
		>
			<Fade in={open}>
				<div className={classes.modalRoot}>
					<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#00a099', borderRadius: 7 }}>
						{/*
									//! => h5
								*/}
						<Typography variant='h6' style={{ color: 'white', alignSelf: 'center', marginTop: 4, marginBottom: 4 }}>Produtos vinculados ao   {cliTit}</Typography>
						<div style={{ flex: 1, display: 'flex' }}>
							<div style={{ flex: 0.1 }}>
								{/*
									//! => h6
								*/}
								<Typography variant='subtitle1' style={{ color: 'white', marginLeft: 2 }}>Código</Typography>
							</div>
							<div style={{ flex: 0.7 }}>
								{/*
									//! => h6
								*/}
								<Typography variant='subtitle1' style={{ color: 'white', marginLeft: 10 }}>Descrição</Typography>
							</div>
							<div style={{ flex: 0.2, textAlign: 'right' }}>
								{/*
									//! => h6
								*/}
								<Typography variant='subtitle1' style={{ color: 'white', marginRight: 10 }}>Taxa ADM (%)</Typography>
							</div>
						</div>
					</div>
					<div style={{ flex: 1, overflowY: 'scroll' }}>
						{
							itens.map((item, idx) => {
								return (
									(item.cli === cliente) ?
										<ButtonBase
											key={idx}
											style={{ backgroundColor: (idx % 2) ? '#eeeeee' : null, padding: 10, display: 'flex', flex: 1, width: '100%' }}
											onClick={() => select(item.pro)}
										>
											{/*
												//! => h6
											*/}
											<div style={{ flex: 0.1, textAlign: 'left' }}>
												<Typography variant='h7'>{item.pro}</Typography>
											</div>
											{/*
												//! => subtitle1
											*/}
											<div style={{ flex: 0.7, textAlign: 'left' }}>
												<Typography variant='caption' style={{ marginLeft: 10 }}>{item.des}</Typography>
												{
													(item.img) &&
													<img
														style={{ height: 80, width: 150 }}
														src={`data:image/png;base64,${item.img}`}
														alt={item.pro}
													/>
												}
											</div>
											<div style={{ flex: 0.2, textAlign: 'right' }}>
												{/*
													//! => subtitle1
												*/}
												<Typography variant='caption' style={{ marginRight: 10 }}>{item.tax}</Typography>
											</div>
										</ButtonBase>
										:
										null
								)
							})
						}
					</div>
					<Tooltip style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} title={'Fechar'} placement='top-start'>
						<Fab
							color='primary'
							aria-label='Fechar'
							size='small'
							type='button'
							onClick={() => setOpen(false)}
						>
							<CloseIcon />
						</Fab>
					</Tooltip>
				</div>
			</Fade>
		</Modal>
	)
}

export default Pro01
