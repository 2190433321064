import React from 'react'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Fade, Fab, Tooltip, Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CloseIcon from '@material-ui/icons/Close'

const Ajd01 = ({ open, setOpen }) => {

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalRoot: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '7px',
            boxShadow: theme.shadows[5],
            //padding: theme.spacing(2, 4, 3),
            height: '70%',
            minWidth: 1000,
            flex: (midea680) ? 1 : 0,
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            marginBottom: 15,
        },
        item: {
            marginBottom: 10,
        },
    }))

    const classes = useStyles()
    const midea680 = useMediaQuery('(max-width:680px)')

    return (
        <Modal
            style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            open={open}
            onClose={() => setOpen(false)}
        >
            <Fade in={open}>
                <div className={classes.modalRoot}>
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#00a099', borderRadius: 7 }}>
                        <Typography variant="h5" style={{ color: 'white', alignSelf: 'center', marginTop: 4, marginBottom: 4 }}>Ajuda</Typography>
                    </div>
                    <div style={{ flex: 1, overflowY: 'scroll', padding: 15, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h4" className={classes.title}>Instruções de uso:</Typography>
                        <Typography variant="h7" className={classes.item}>- Quando aberta a tela pela primeira vez, é necessário salvar os registros para que o carregamento seja incluído de fato no sistema.</Typography>
                        <Typography variant="h7" className={classes.item}>- Todos os registros alterados ficam destacados em vermelho. Essas alterações ficam pendentes até que o carregamento seja salvo.</Typography>
                        <Typography variant="h7" className={classes.item}>- Duplo clique na coluna "CPF" marca o registro para ser excluído.</Typography>
                        <Typography variant="h7" className={classes.item}>- Registros selecionados para a exclusão ficam marcados com a cor verde escura e são excluídos através do botão "-"</Typography>
                        <Typography variant="h7" className={classes.item}>- Todos os registros excluídos ficam pendentes até que o carregamento seja salvo.</Typography>
                        <Typography variant="h7" className={classes.item}>- Tela de inclusão: Campos com uma Lupa ao lado direito devem sempre ser preenchidos selecionando um dos registros sugeridos.</Typography>
                        <Typography variant="h7" className={classes.item}>- Deve-se selecionar a opção de finalizar apenas quando todas as alterações necessárias forem feitas, pois este processo envia o carregamento para a THCPay e não pode mais ser alterado.</Typography>

                        <Typography variant="h4" className={classes.title}>Comandos na tabela:</Typography>
                        <Typography variant="h5" className={classes.title}>- Movimentação na tabela:</Typography>
                        <Typography variant="h7" className={classes.item}>- Próxima coluna: Botão TAB.</Typography>
                        <Typography variant="h7" className={classes.item}>- Coluna anterior: Botão TAB com o SHIFT pressionado.</Typography>
                        <Typography variant="h7" className={classes.item}>- Registro anterior: Seta para cima.</Typography>
                        <Typography variant="h7" className={classes.item}>- Registro posterior: Seta para baixo.</Typography>
                        <Typography variant="h5" className={classes.title}>- Outras ações:</Typography>
                        <Typography variant="h7" className={classes.item}>- Botão "+": Abre a tela para adicionar novo favorecido.</Typography>
                        <Typography variant="h7" className={classes.item}>- Botão "-": Executa a função de excluir os favorecidos selecionados.</Typography>
                        <Typography variant="h7" className={classes.item}>- Colunas "Novo cartão" e "C.Poupança" só permitem as opções "Sim" e "Não" e só podem ser alteradas através do duplo clique.</Typography>
                        <Typography variant="h7" className={classes.item}>- Coluna "Banco" só pode ser alterada através da seleção que é acionada através do duplo clique.</Typography>
                    </div>
                    <Tooltip style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} title={'Fechar'} placement="top-start">
                        <Fab
                            color="primary"
                            aria-label="Fechar"
                            size="small"
                            type="button"
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon />
                        </Fab>
                    </Tooltip>
                </div>
            </Fade>
        </Modal>
    )
}

export default Ajd01
