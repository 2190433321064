import React, { useState, useEffect, useCallback } from 'react'

// HPro
import { process, delayedAlert } from '../../components/HproUtils'
import fileDownload from 'js-file-download'
import Hproalert from '../../components/Hproalert'
import Hpromainbuttons from '../../components/Hpromainbuttons'
import HproLoading from '../../components/HproLoading'
import Hproatencao from '../../components/Hproatencao'
import logoImg from '../../assets/logo.png'
import { format } from '../../components/Rotinas'

// M-UI e Outros
import { TextField, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'

function useWindowSize() {
	const isClient = typeof window === 'object'
	const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })

	useEffect(() => {
		if (!isClient) {
			return false
		}

		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight
			})
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [isClient])

	return windowSize
}

let cIni = ''
let cNfs = ''
let cFin = ''

const ConsultaCarreg = ({ theme }) => {

	const size = useWindowSize()

	const useStyles = makeStyles((theme) => ({
		wrapper: {
			flex: 1,
			height: size.height - 65,
		},
		titlePanel: {
			alignItems: 'center', //! => Remove
			// backgroundColor: '#f5f5f5',
			backgroundColor: 'white',
			display: 'flex',
			flexDirection: 'row',
			position: 'absolute',
			top: 65,
			width: size.width - 110,
			height: 80,
			zIndex: 10,
		},
		titleLabel: {
			marginLeft: 20,
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
		},
		gridItemStyle: {
			marginLeft: 10,
			fontSize: 12, //! => 14
			fontWeight: 'bold',
			overflow: 'hidden',
		},
		gridSubItemStyle: {
			marginLeft: 10,
			fontSize: 12, //! => 14
			overflow: 'hidden',
		},
	}))

	const history = useHistory()
	const classes = useStyles()
	let { cli } = useParams()

	const [loading, setLoading] = useState(false)
	const [canOpen, setCanOpen] = useState(false)
	const [dupOpen, setDupOpen] = useState(false)
	const [openMsg, setOpenMsg] = useState(false)
	const [msg, setMsg] = useState('')
	const [carregamentos, setCarregamentos] = useState([])
	const [cliInfo, setCliInfo] = useState('')
	const [selected, setSelected] = useState('')
	const [ini, setIni] = useState('')
	const [fin, setFin] = useState('')
	const [nfs, setNfs] = useState('')
	const [download, setDownload] = useState({})

	const gerarNfs = useCallback(async () => {
		setLoading(true)
		try {
			try {
				const processResponse = await process('DOWNLOADNOTAFISCAL', { nom: localStorage.getItem('TouchWeb_UserName'), num: selected.replace('/', '') })
				const processResponseObj = JSON.parse(processResponse)
				const success = processResponseObj.success
				const content = processResponseObj.content

				if (success) {
					setDownload({
						name: selected + '.pdf',
						link: content.notainfo.b64
					})
				} else {
					setMsg(content.message)
					setOpenMsg(true)
				}

			} catch (error) {
				delayedAlert('Error', error.message)
			}
		} finally {
			setLoading(false)
		}
	}, [selected])

	useEffect(() => {
		let cDat = new Date()
		cIni = `${cDat.getFullYear() - 1}-01-01`
		cFin = `${cDat.getFullYear()}-${format(cDat.getMonth() + 1, 2)}-${format(cDat.getDate(), 2)}`

		setIni(cIni)
		setFin(cFin)
	}, [])

	useEffect(() => {
		if (selected && download.link) {
			console.log(download)
			fileDownload(Buffer.from(download.link, 'base64'), download.name)
		}
	}, [selected, download])

	const listaCarregamentos = useCallback(async () => {
		setLoading(true)
		try {
			try {
				const processResponse = await process('LISTACARREGAMENTOS', {
					nom: localStorage.getItem('TouchWeb_UserName'),
					cli,
					nfs: cNfs,
					ini: cIni,
					fin: cFin
				})
				const processResponseObj = JSON.parse(processResponse)
				const success = processResponseObj.success
				const content = processResponseObj.content

				if (success) {
					setCarregamentos(content.hwebcar)
					setCliInfo(content.cliInfo)
				} else {
					setMsg(content.message)
					setOpenMsg(true)
				}

			} catch (error) {
				delayedAlert('Error', error.message)
			}
		} finally {
			setSelected('')
			setLoading(false)
		}
	}, [cli])

	useEffect(() => {
		listaCarregamentos()
	}, [listaCarregamentos])

	useEffect(() => {
		console.log(download)
	}, [download])

	const valida = () => {
		if (!selected) {
			setMsg('Nenhum carregamento foi selecionado')
			setOpenMsg(true)
			setLoading(false)
			return false
		} else {
			return true
		}
	}

	const handleDup = async () => {
		setLoading(true)
		try {
			const processResponse = await process('DUPLICACARREGAMENTO', { nom: localStorage.getItem('TouchWeb_UserName'), num: selected })
			const processResponseObj = JSON.parse(processResponse)
			const success = processResponseObj.success
			const content = processResponseObj.content

			setDupOpen(false)
			if (success) {
				listaCarregamentos()
			} else {
				setMsg(content.message)
				setOpenMsg(true)
				setLoading(false)
			}

		} catch (error) {
			delayedAlert('Error', error.message)
			setLoading(false)
		}
	}

	const handleCancel = async () => {
		setLoading(true)
		try {
			const processResponse = await process('CANCELCARREGAMENTO', { nom: localStorage.getItem('TouchWeb_UserName'), num: selected })
			const processResponseObj = JSON.parse(processResponse)
			const success = processResponseObj.success
			const content = processResponseObj.content

			if (success) {
				setCanOpen(false)
				setSelected('')
				listaCarregamentos()
			} else {
				setCanOpen(false)
				setMsg(content.message)
				setOpenMsg(true)
				setLoading(false)
			}

		} catch (error) {
			delayedAlert('Error', error.message)
			setLoading(false)
		}
	}

	const handleClose = () => {
		history.push("/main")
	}

	const FilterTitle = ({ title }) => {
		return ( //! => 16
			<Typography style={{ color: '#00a099', fontSize: 14, fontWeight: 'bold' }} variant="caption" >{title}</Typography>
		)
	}

	const GridTitle = ({ nWid, title }) => {
		return ( //! => 16
			<div style={{ width: nWid, backgroundColor: '#00a099' }}>
				<Typography style={{ marginLeft: 10, color: 'white', fontSize: 14, fontWeight: 'bold' }} variant="caption" >{title}</Typography>
			</div>
		)
	}

	const GridItem = ({ nWid, text, subtext = '' }) => {
		return (
			<div style={{ width: nWid, display: 'flex', flexDirection: 'column' }}>
				<Typography className={classes.gridItemStyle} variant="caption" >{text}</Typography>
				{
					(subtext) &&
					<Typography className={classes.gridSubItemStyle} variant="caption" >{subtext}</Typography>
				}
			</div>
		)
	}

	return (
		<div className={classes.wrapper}>
			<div className={classes.titlePanel}>
				<div className={classes.titleLabel}>
					<FilterTitle title={`Cliente: ${cliInfo}`} />
					<div style={{ marginTop: 5 }}>
						<TextField
							label="Nº NFS"
							variant="outlined"
							value={nfs}
							onChange={(e) => {
								setIni(' ')
								setFin(' ')
								setNfs(e.target.value)
								setCarregamentos([])
							}}
							size="small"
							style={{ width: 175, marginRight: 20 }}
						/>
						<TextField
							label="Data inicial"
							variant="outlined"
							value={ini}
							onChange={(e) => {
								setNfs('')
								setIni(e.target.value)
								setCarregamentos([])
							}}
							size="small"
							style={{ width: 175, marginRight: 20 }}
							type="date"
						/>
						<TextField
							label="Data final"
							variant="outlined"
							value={fin}
							onChange={(e) => {
								setNfs('')
								setFin(e.target.value)
								setCarregamentos([])
							}}
							size="small"
							style={{ width: 175, marginRight: 20 }}
							type="date"
						/>
						<Button variant="contained" color='primary'
							onClick={() => {
								cIni = ini
								cFin = fin
								cNfs = nfs
								listaCarregamentos()
							}}
						>
							Processar
						</Button>
					</div>
				</div>
				<div>
					<img style={{ height: 80, marginRight: 20 }} src={logoImg} alt="THCPay" />
				</div>
			</div>

			<div style={{ position: 'sticky', top: 80, display: 'flex', flexDirection: 'column', zIndex: 9 }}>
				<div style={{ display: 'flex' }}>
					<GridTitle nWid={100} title='Número' />
					{
						(cliInfo === '999999 - TODOS') &&
						<GridTitle nWid={350} title='Cliente' />
					}
					<GridTitle nWid={350} title='Produto' />
					<GridTitle nWid={150} title='Vl.Premiação' />
					<GridTitle nWid={100} title='Taxa %' />
					<GridTitle nWid={120} title='Vl.Taxa' />
					<GridTitle nWid={120} title='Vl.Tarifa' />
					<GridTitle nWid={120} title='Vl.Total' />
					<GridTitle nWid={100} title='Nº NFS' />
					<GridTitle nWid={100} title='Situação' />
					<GridTitle nWid={200} title='Inclusão' />
				</div>
			</div>

			<div style={{ position: 'relative', top: 80, display: 'flex', flexDirection: 'column' }}>
				{
					carregamentos.map((item, idx) => {
						return (
							<div
								key={item.num}
								onClick={() => setSelected(item.num)}
								style={{
									alignItems: 'center', //! => Remove
									display: 'flex',
									height: 40, //! => 60
									backgroundColor:
										(selected === item.num) ? '#998a08' :
											(idx % 2 === 0) ? '#f5f5f5' : null,
									padding: 5
								}}
							>
								<GridItem nWid={100} text={item.num} />
								{
									(cliInfo === '999999 - TODOS') &&
									<GridItem nWid={350} text={`${item.cli} - ${item.raz}`} subtext={`${item.cpx} - ${item.cid}/${item.est}`} />
								}
								<GridItem nWid={350} text={`${item.pro} - ${item.des}`} />
								<GridItem nWid={150} text={item.val} />
								<GridItem nWid={100} text={item.tax} />
								<GridItem nWid={120} text={item.vat} />
								<GridItem nWid={120} text={item.taf} />
								<GridItem nWid={120} text={item.tot} />
								<GridItem nWid={120} text={item.nnf} />
								<GridItem nWid={100} text={item.sit} />
								<GridItem nWid={200} text={`${item.dat} às ${item.hoc}`} />
							</div>
						)
					}
					)
				}
			</div>

			<Hproalert
				type="error"
				message={msg}
				isOpen={openMsg}
				handleCloseWarning={setOpenMsg}
			/>
			<Hproatencao
				title="Confirma o cancelamento do carregamento?"
				isOpen={canOpen}
				onSim={handleCancel}
				onNao={() => setCanOpen(false)}
				buttons={2}
			/>
			<Hproatencao
				title="Confirma a duplicação do carregamento selecionado?"
				isOpen={dupOpen}
				onSim={handleDup}
				onNao={() => setDupOpen(false)}
				buttons={2}
			/>
			<Hpromainbuttons aButtons={[
				{ name: 'Baixar NFS', func: () => (valida()) && gerarNfs(), disabled: !Boolean(carregamentos.find(carregamento => carregamento.num === selected)?.nnf), download },
				{ name: 'Duplicar', func: () => (valida()) && setDupOpen(true) },
				{ name: 'Cancelar', func: () => (valida()) && setCanOpen(true) },
				{ name: 'Consultar', func: () => (valida()) && history.push(`/detalhacarreg/${selected.replace('/', '')}`) },
				{ name: 'Fechar', func: handleClose },
			]} />
			<HproLoading loading={loading} />
		</div>
	)
}

export default ConsultaCarreg
