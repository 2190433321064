import React, { useState } from 'react'
import InputFormik from '../../formikComponents/InputFormik'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Person from '@material-ui/icons/Person'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'
import { setInfo } from '../../controllers'
import { process, delayedAlert } from '../../components/HproUtils';
import Hproalert from '../../components/Hproalert';
import useMediaQuery from '@material-ui/core/useMediaQuery'

//Imager
import logoImg from '../../assets/logo.png'

import './styles.css'


const initialValues = {
    username: '',
    password: '',
}

const validationSchema = Yup.object().shape({
    username: Yup.string().required("Campo obrigatório"),
    password: Yup.string().required("Campo obrigatório")
})

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        color: theme.primary,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -12,
    },
    wrapper: {
        position: 'relative',
        width: '100%'
    },
    loginButton: {
        width: '100%',
    },
    titleClass: {
        color: theme.palette.primary.main,
        fontSize: 26,
        fontWeight: 'bold',
        marginLeft: 10,
    },
    subtitleClass: {
        color: '#575756',
        fontSize: 16,
        fontWeight: 'bold',
        marginLeft: 25,
    },
}));

export default function LoginScreen() {

    const [loading, setLoading] = useState(false)
    const [msg, setMsg] = useState('')
    const [openMsg, setOpenMsg] = useState(false)
    const media1040 = useMediaQuery('(max-width:1040px)')
    const history = useHistory()
    const classes = useStyles();

    const submitForm = async (values) => {
        try {
            setLoading(true)

            const userInfo = JSON.stringify(values)
            const data = JSON.parse(userInfo)

            const parameters = {
                nom: data.username,
                sen: data.password
            }

            const processResponse = await process('LOGINERP', parameters);
            setLoading(false)

            const processResponseObj = JSON.parse(processResponse)
            const success = processResponseObj.success
            const message = processResponseObj.message

            if (success) {
                setInfo(data.username.toUpperCase())
                history.push("/main")
            } else {
                if (message) {
                    setMsg(message)
                } else {
                    setMsg('Atenção, usuário e/ou senha inválidos')
                }
                setOpenMsg(true)
            }
        } catch (error) {
            setLoading(false);

            delayedAlert('Error', error.message);
        }
    }

    return (
        <div style={{
            display: 'flex',
            flex: (media1040) ? 1 : 0.3,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#00a099'
        }}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => submitForm(values)}
            >
                {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
                    <div style={{ backgroundColor: 'white', borderRadius: 15, padding: 15 }}>
                        <section className="form">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ margin: 15 }} src={logoImg} alt="Logo" />
                            </div>
                            <form onSubmit={handleSubmit} style={{ margin: 15 }}>
                                <InputFormik
                                    fullWidth
                                    required
                                    name="username"
                                    label="Usuário"
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                />
                                <InputFormik
                                    fullWidth
                                    required
                                    name="password"
                                    label="Senha"
                                    variant="outlined"
                                    margin="normal"
                                    type="password"
                                />
                                <div className={classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.loginButton}
                                        size="small"
                                        startIcon={<Person />}
                                        style={{ marginTop: '5px' }}
                                        type="submit"
                                        disabled={loading}
                                    >
                                        Entrar
                                    </Button>
                                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </form>
                        </section>
                    </div>
                )}
            </Formik>
            <Hproalert
                type="error"
                message={msg}
                isOpen={openMsg}
                handleCloseWarning={setOpenMsg}
            />
        </div>
    )
}
