import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { _ArrayMenuSelect } from '../../controllers';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import logoImg from '../../assets/logo.png'
import logoImgMob from '../../assets/logo.png'

// Hook
function useWindowSize() {
	const isClient = typeof window === 'object'
	const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })

	useEffect(() => {
		if (!isClient) {
			return false;
		}

		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight
			});
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [isClient]);

	return windowSize;
}

const MainMenu = () => {
	const size = useWindowSize();
	_ArrayMenuSelect([1, 0, 0, 0, 0, 0, 0])
	const useStyles = makeStyles((theme) => ({
		mainDiv: {
			flex: 1,
			height: size.height - 75,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column'
		},
	}));
	const classes = useStyles();
	const midea685 = useMediaQuery('(max-width:745px)');
	return (
		<>
			<div className={classes.mainDiv}>
				{(midea685) ? <img src={logoImgMob} alt="THCPay" /> : <img src={logoImg} alt="THCPay" />}
			</div>
		</>
	)
}

export default MainMenu
