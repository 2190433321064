import React, { useState, useEffect } from 'react'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Fade, Fab, Tooltip, Typography, ButtonBase, TextField } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CloseIcon from '@material-ui/icons/Close'

const Cli01 = ({ open, setOpen, select, itens }) => {

	const usuario = localStorage.getItem('TouchWeb_UserName')

	const useStyles = makeStyles((theme) => ({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		modalRoot: {
			backgroundColor: theme.palette.background.paper,
			borderRadius: '7px',
			boxShadow: theme.shadows[5],
			//padding: theme.spacing(2, 4, 3),
			height: '70%',
			minWidth: 1000,
			flex: (midea680) ? 1 : 0,
			display: 'flex',
			flexDirection: 'column',
		},
	}))

	const classes = useStyles()
	const midea680 = useMediaQuery('(max-width:680px)')
	const [pesquisa, setPesquisa] = useState('')
	const [aaux, setAaux] = useState([])

	useEffect(() => {
		setAaux(itens)
		setPesquisa('')
	}, [itens, open])

	const handleFilter = (e) => {
		let cAux = e.target.value
		setAaux(itens)
		setAaux(prev => prev.filter(x => {
			const upperCaseText = cAux.toUpperCase()
			if (x.cli.toUpperCase().indexOf(upperCaseText) !== -1) { return true }
			if (x.raz.toString().toUpperCase().indexOf(upperCaseText) !== -1) { return true }
			if (x.cpx.toString().toUpperCase().indexOf(upperCaseText) !== -1) { return true }
			if (x.cid.toString().toUpperCase().indexOf(upperCaseText) !== -1) { return true }

			return false
		}))
		setPesquisa(cAux)
	}

	return (
		<Modal
			style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
			open={open}
			onClose={() => setOpen(false)}
		>
			<Fade in={open}>
				<div className={classes.modalRoot}>
					<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#00a099', borderRadius: 7 }}>
						{/*
							//! => h5
						*/}
						<Typography variant="h6" style={{ color: 'white', alignSelf: 'center', marginTop: 4, marginBottom: 4 }}>Clientes vinculados ao usuário {usuario}</Typography>
						<div style={{ padding: 10, backgroundColor: 'white' }}>
							<TextField
								label="Pesquisar"
								variant="outlined"
								value={pesquisa}
								onChange={(e) => handleFilter(e)}
								size="small"
								color="white"
							/>
						</div>
						<div style={{ flex: 1, display: 'flex' }}>
							<div style={{ flex: 1 }}>
								{/*
									//! => h6
								*/}
								<Typography variant="subtitle1" style={{ color: 'white', marginLeft: 2 }}>Código</Typography>
							</div>
							<div style={{ flex: 4 }}>
								{/*
									//! => h6
								*/}
								<Typography variant="subtitle1" style={{ color: 'white', marginLeft: 10 }}>Razão Social</Typography>
							</div>
							<div style={{ flex: 2 }}>
								{/*
									//! => h6
								*/}
								<Typography variant="subtitle1" style={{ color: 'white', marginLeft: 10 }}>CNPJ</Typography>
							</div>
							<div style={{ flex: 3 }}>
								{/*
									//! => h6
								*/}
								<Typography variant="subtitle1" style={{ color: 'white', marginLeft: 10 }}>Cidade/UF</Typography>
							</div>
						</div>
					</div>
					<div style={{ flex: 1, overflowY: 'scroll' }}>
						{
							aaux.map((item, idx) => {
								return (
									<ButtonBase
										key={idx}
										style={{ backgroundColor: (idx % 2) ? '#eeeeee' : null, padding: 10, display: 'flex', flex: 1, width: '100%' }}
										onClick={() => select(item.cli, item.raz)}
									>
										{/*
											//! => h6
										*/}
										<div style={{ flex: 1, textAlign: 'left' }}>
											<Typography variant="h7">{item.cli}</Typography>
										</div>
										{/*
											//! => subtitle2
										*/}
										<div style={{ flex: 4, textAlign: 'left' }}>
											<Typography variant="caption" style={{ marginLeft: 10 }}>{item.raz}</Typography>
										</div>
										{/*
											//! => subtitle2
										*/}
										<div style={{ flex: 2, textAlign: 'left' }}>
											<Typography variant="caption" style={{ marginLeft: 10 }}>{item.cpx}</Typography>
										</div>
										{/*
											//! => subtitle2
										*/}
										<div style={{ flex: 3, textAlign: 'left' }}>
											<Typography variant="caption" style={{ marginLeft: 10 }}>{item.cid}/{item.est}</Typography>
										</div>
									</ButtonBase>
								)
							})
						}
					</div>
					<Tooltip style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} title={'Fechar'} placement="top-start">
						<Fab
							color="primary"
							aria-label="Fechar"
							size="small"
							type="button"
							onClick={() => setOpen(false)}
						>
							<CloseIcon />
						</Fab>
					</Tooltip>
				</div>
			</Fade>
		</Modal>
	)
}

export default Cli01
