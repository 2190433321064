import React, { useState, useEffect } from 'react'
import InputFormik from '../../formikComponents/InputFormik'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Person from '@material-ui/icons/Person'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { process, delayedAlert } from '../../components/HproUtils'
import Hproalert from '../../components/Hproalert'
//import useMediaQuery from '@material-ui/core/useMediaQuery'

import { setArrayMenuSelect } from '../../controllers'

const initialValues = {    
    password: '',
    newPassword: '',
    cfmPassword: '',
}

const validationSchema = Yup.object().shape({
    password: Yup.string().required("Campo obrigatório"),
    newPassword: Yup.string().required("Campo obrigatório").max(10, 'Limite de caracteres excedido (10)'),
    cfmPassword: Yup.string().required("Campo obrigatório").max(10, 'Limite de caracteres excedido (10)'),
})

// Hook
function useWindowSize() {
    const isClient = typeof window === 'object'
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })
    
    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isClient]);

    return windowSize;
}

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        color: theme.primary,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -12,
    },
    wrapper: {
        position: 'relative',
        width: '100%'
    },
    loginButton: {
        width: '100%',
    },
    titleClass: {
        color: theme.palette.primary.main,
        fontSize: 26,
        fontWeight: 'bold',
        marginLeft: 10,
    },
    subtitleClass: {
        color: '#575756',
        fontSize: 16,
        fontWeight: 'bold',
        marginLeft: 25,
    },
}));

export default function TrocarSenha() {

    const size = useWindowSize()

    const [loading, setLoading] = useState(false)
    const [msg, setMsg] = useState('')
    const [openMsg, setOpenMsg] = useState(false)
    //const media1040 = useMediaQuery('(max-width:1040px)')
    const history = useHistory()
    const classes = useStyles()
    const [isSubmited, setIsSubmited] = useState(false)

    const submitForm = async (values) => {
        try {
            setLoading(true)

            const userInfo = JSON.stringify(values)
            const data = JSON.parse(userInfo)

            if (data.newPassword !== data.cfmPassword) {
                setMsg('Confirmação de senha difere da senha informada')
                setOpenMsg(true)
                setLoading(false)
                return
            }            

            const parameters = {
                nom: localStorage.getItem('TouchWeb_UserName'),
                sen: data.password,
                new: data.newPassword,
            }

            const processResponse = await process('TROCASENHA', parameters);            
            const processResponseObj = JSON.parse(processResponse)
            const success = processResponseObj.success
            const content = processResponseObj.content

            setLoading(false)

            if (success) {
                setArrayMenuSelect([1, 0, 0, 0])
                history.push("/main")
            } else {
                setMsg(content.message)
                setOpenMsg(true)
            }

        } catch (error) {
            setLoading(false)
            delayedAlert('Error', error.message);
        }
    }

    return (
        <div style={{
            flex: 1,
            height: size.height - 75,        
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => submitForm(values)}
            >
                {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
                    <form onSubmit={handleSubmit} style={{ backgroundColor: 'white', padding: 15, width: 450 }}>                        
                        <InputFormik
                            fullWidth
                            required
                            name="password"
                            label="Senha atual"
                            variant="outlined"
                            margin="normal"
                            type="password"
                        />
                        <InputFormik
                            fullWidth
                            required
                            name="newPassword"
                            label="Nova senha"
                            variant="outlined"
                            margin="normal"
                            type="password"
                            showMessage
                            submited={isSubmited}
                        />
                        <InputFormik
                            fullWidth
                            required
                            name="cfmPassword"
                            label="Confirmar senha"
                            variant="outlined"
                            margin="normal"
                            type="password"
                            showMessage
                            submited={isSubmited}
                        />
                        <div className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.loginButton}
                                size="small"
                                startIcon={<Person />}
                                style={{ marginTop: '5px' }}
                                type="submit"
                                disabled={loading}
                                onClick={() => setIsSubmited(true)}
                            >
                                Trocar senha
                            </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </form>
                )}
            </Formik>
        <Hproalert
            type="error"
            message={msg}
            isOpen={openMsg}
            handleCloseWarning={setOpenMsg}
        />
        </div>
    )
}