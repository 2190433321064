import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField';
import { Field, getIn } from 'formik'

export const MaterialInputFormik = ({
    field,
    form,
    disabled,
    showMessage,
    helperText,
    onChange,
    onExit,
    manually,
    aOptions,
    submited,
    multiline = false,
    rows = 1,
    max = 0,
    ...props
}) => {
    const { name, value, onBlur, ...otherFieldProps } = field
    const { errors, isSubmitting, setFieldValue } = form

    const fieldError = getIn(errors, name)
    //const showError = getIn(touched, name) && !!fieldError && submited
    const showError = !!fieldError && submited

    const handleChange = e => {
        const newValue = (max) ? e.target.value.slice(0, max) : e.target.value
        
        if (onChange && typeof onChange === 'function') {
            onChange(newValue)
        }
        if (!manually) {
            setFieldValue(name, newValue, false)
        }
    }

    const handleBlur = e => {
        const newValue = e.target.value
        onBlur(e)
        if (onExit && typeof onExit === 'function') {
            onExit(newValue)
        }
    }

    return (
        <>
            {   (aOptions) ?
                <TextField
                    {...props}
                    {...field}
                    {...otherFieldProps}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={name}
                    value={value === null ? '' : value}    
                    disabled={(disabled !== undefined ? disabled : isSubmitting)}
                    error={showError}
                    helperText={showMessage ? (showError ? fieldError : helperText) : undefined}
                    size={"small"}
                    margin={"dense"}
                    color="primary"
                >
                    {   (aOptions) ?
                            aOptions.map((item,idx) => {
                                return(
                                    <option key={idx} value={item}>
                                        {item}
                                    </option>
                                )
                            })
                        :
                            null
                    }
                </TextField>
            :
                <TextField
                    {...props}
                    {...field}
                    {...otherFieldProps}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={name}
                    value={value === null ? '' : value}             
                    disabled={disabled !== undefined ? disabled : isSubmitting}
                    error={showError}
                    helperText={showMessage ? (showError ? fieldError : helperText) : undefined}
                    size={"small"}
                    margin={"dense"}
                    color="primary"
                    multiline={multiline}
                    rows={rows}
                />
            }
        </>
    )
}

MaterialInputFormik.defaultProps = {
    showMessage: false,
}

MaterialInputFormik.propTypes = {
    disabled: PropTypes.bool,
    showMessage: PropTypes.bool,
    helperText: PropTypes.string,
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func,
}

const InputFormik = props => <Field {...props} component={MaterialInputFormik} />

InputFormik.propTypes = {
    name: PropTypes.string.isRequired
}

export default InputFormik