/*
========================
||     HPRO ALERT     ||
========================

---------------------------------------
Criado em 12/06/2020 por IP
Atualizações:
    -
---------------------------------------
 
TIPOS PARA PASSAR NO "TYPE":
    - success -> Fica verde
    - warning -> fica laranja
    - error -> fica vermelho (Padrão)
    - info -> fica azul

message: Pode passar qualquer string, vai sem exibida no alert

*** Como usar:
    - No formulário que chama esse alert tem que ter um useState (Boolean) que vai ser passado para o "isOpen"
e no "handleCloseWarning" será passado a função setando essa variável como falsa. Segue exemplo:

-------------------------------------------------------------
********* EXEMPLO
-------------------------------------------------------------
const [openMsg,setOpenMsg] = useState(false)

if (success) {
    setOpenMsg(true)  -> Função para deixar visível            
}

.
. Código
.

<Hproalert 
    type='success' 
    message='deu tudo certo' 
    isOpen={openMsg} 
    handleCloseWarning={setOpenMsg} -> Função para deixar invisível
/>
-------------------------------------------------------------
*/

//AFB Alteração no handleCloseWarning; passar somente a referencia dia 19/08/2020

import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Hproalert = ({ isOpen, message = '', type = "error", handleCloseWarning }) => {    
    return(
        <div>
            <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleCloseWarning}>
                <Alert onClose={() => handleCloseWarning()} severity={type}>
                    {message}
                </Alert>
            </Snackbar>
        </div>   
    )
}

export default Hproalert