import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InputBase, List, ListItem, ListItemText } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom'

import { setArrayMenuSelect } from '../controllers'

import navMenus from './Menus'

const Hprosearch = ({ value }) => {         

    const [color, setColor] = useState('#0a736f')
    const [dwidth,setDwidth] = useState(300)
    const [showMenu, setShowMenu] = useState(false)
    const [menusShown, setMenusShown] = useState(navMenus)

    const useStyles = makeStyles(theme => ({
        paperClass: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: dwidth,
            height: 30,
            backgroundColor: color,
            borderRadius: 5,
            transition: 'width 0.5s',
        },
        inputClass: {
            marginLeft: 5,
            flex: 1,
            opacity: 1,
            color: 'white',
            fontSize: 13
        },
        menuDiv: {
            backgroundColor: '#EAEBEA',
            borderRadius: 5,
            padding: 5,
            position: 'absolute',
            top: 50,
            width: 350,
            maxHeight: 350,
            overflow: 'auto',
            zIndex: 999,
        },
        labelClass: {
            color: '#2F2F2F',
            size: 50,
        },
        itemListClass: {
            height: 35,
        }
    }));

    const classes = useStyles()  
    const history = useHistory()    

    const handleFocus = () => {
        setDwidth(350)
        setShowMenu(true)
    }

    const handleBlur = () => {
        setDwidth(300)       
        setShowMenu(false) 
    }

    const handlemenuClick = (item) => {
        let aAux = [0, 0, 0, 0]
        aAux[item.menuColor] = 1

        setArrayMenuSelect(aAux)

        if (item.link) {
            history.push(item.link)                    
        }
        setShowMenu(false)
    }

    const filterMenus = (e) => {
        let newArray = navMenus
        let filteredArray = newArray.filter(function(item) {
            let n = item.searchtext.toUpperCase().indexOf(e.toUpperCase()) 
            return n > -1
        })
        if (filteredArray.length === 0) {
            filteredArray.push({    
                searchtext: 'Não existem pesquisas compatíveis',
                link: '' })
        }
        setMenusShown(filteredArray)
    }

    const suggestionsListComponent = (
        <List className={classes.menuDiv}>
            {menusShown.map((item) => {
                return (
                    <ListItem 
                        button
                        divider 
                        key={item.searchtext} 
                        className={classes.labelClass}
                        onMouseDown={() => handlemenuClick(item)}
                    >
                        <ListItemText primary={item.searchtext} />
                    </ListItem>
                )
            })
            }
        </List>
    );    
    
    return(
        <div className={classes.wraperClass}>
            <div className={classes.paperClass} 
                onFocus={handleFocus} 
                onBlur={handleBlur} 
                onMouseOver={() => setColor('#4fbfbb')} 
                onMouseOut={() => setColor('#0a736f')}
            >
                <SearchIcon style={{ color: '#ffffff', marginLeft: '10px', marginRight: '10px' }} />
                <InputBase
                    className={classes.inputClass}
                    placeholder="Procure um menu"
                    type="text"
                    //onChange={(e) => setMenutext(e.target.value)}
                    onChange={(e) => filterMenus(e.target.value)}
                />                
            </div>     
            {   showMenu ? 
                suggestionsListComponent : 
                null
            }
        </div>
    )
}

export default Hprosearch