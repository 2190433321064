//ArrayComMenuSelecionado
export let _ArrayMenuSelect;
export function setArrayMenuSelect (arrayMenuSelect) {
    _ArrayMenuSelect = arrayMenuSelect;
}

//MenuCadastro
export let _toggleMenuFunction;
export function setToggleMenuFunction (toggleMenuFunction) {
    _toggleMenuFunction = toggleMenuFunction;
}
/////////////////////////////////////////////////////////////

//Login
export let infos = [{
    globalUserName: '',    
    globalCnpj: '',
    globalRazSocial: '',
    globalSupervisor: '',
}]

//export const setInfo = (username, a10, a11, a12, usuario) => {
export const setInfo = (username) => {
    infos.globalUserName   = username
    //infos.clienteconsulta  = a10
    //infos.prevprod         = a11
    //infos.ctremb           = a12

    if (infos.globalUserName !== undefined) {
        localStorage.setItem('TouchWeb_UserName', infos.globalUserName);
        //localStorage.setItem('clienteconsulta', infos.clienteconsulta);
        //localStorage.setItem('prevprod', infos.prevprod);
        //localStorage.setItem('ctremb', infos.ctremb);
        //localStorage.setItem('TouchWeb_Usuario', usuario);
        // localStorage.setItem('Direitos', direitos);
        //direitos.map(item => localStorage.setItem(item.dir, item.per))
    }
}
///////////////////////////////////////////////////////////////////