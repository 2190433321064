import React, { useState, useEffect, useCallback } from 'react'

// HPro
import { process, delayedAlert } from '../../components/HproUtils'
import Hproalert from '../../components/Hproalert'
import Hpromainbuttons from '../../components/Hpromainbuttons'
import HproLoading from '../../components/HproLoading'
import logoImg from '../../assets/logo.png'

// M-UI e Outros
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'

function useWindowSize() {
    const isClient = typeof window === 'object'
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })

    useEffect(() => {
        if (!isClient) {
            return false
        }

        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [isClient])

    return windowSize
}

const DetalhaCarreg = ({ theme }) => {

    const size = useWindowSize()

    const useStyles = makeStyles((theme) => ({
        wrapper: {
            flex: 1,
            height: size.height - 65,
        },
        titlePanel: {
            // backgroundColor: '#f5f5f5',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 65,
            width: size.width - 110,
            height: 80,
            zIndex: 10,
        },
        titleLabel: {
            marginLeft: 20,
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        gridItemStyle: {
            marginLeft: 10,
            fontSize: 14,
            fontWeight: 'bold',
            overflow: 'hidden',
        },
        gridSubItemStyle: {
            marginLeft: 10,
            fontSize: 14,
            overflow: 'hidden',
        },
    }))

    const history = useHistory()
    const classes = useStyles()
    let { num } = useParams()

    const [loading, setLoading] = useState(false)
    const [openMsg, setOpenMsg] = useState(false)
    const [msg, setMsg] = useState('')
    const [favorecidos, setFavorecidos] = useState([])
    const [carInfo, setCarInfo] = useState('')

    const consultaCarr = useCallback(async () => {
        setLoading(true)
        try {
            try {
                const processResponse = await process('LISTAFAVORECIDOS', { nom: localStorage.getItem('TouchWeb_UserName'), num: num })
                const processResponseObj = JSON.parse(processResponse)
                const success = processResponseObj.success
                const content = processResponseObj.content

                if (success) {
                    setFavorecidos(content.hwebfav.sort(function (a, b) {
                        return a.nom < b.nom ? -1 : a.nom > b.nom ? 1 : 0;
                    }))
                    setCarInfo(content.carinfo)
                } else {
                    setMsg(content.message)
                    setOpenMsg(true)
                }

            } catch (error) {
                delayedAlert('Error', error.message)
            }
        } finally {
            setLoading(false)
        }
    }, [num])

    useEffect(() => {
        consultaCarr()
    }, [consultaCarr])

    const handleClose = () => {
        history.goBack()
    }

    const FilterTitle = ({ title, link = false }) => {
        return (
            <>
                {
                    (link && carInfo.b64)
                        ?
                        <div style={{ flexDirection: 'row' }}>
                            <Typography style={{ color: '#00a099', fontSize: 16, fontWeight: 'bold' }} variant="caption" >{title}</Typography>
                            <a download={`${carInfo.nnf}.pdf`} href={`data:application/octet-stream;base64,${carInfo.b64}`}>
                                <Typography style={{ color: '#00a099', fontSize: 16, fontWeight: 'bold' }} variant="caption" > - Baixar NFS {carInfo.nfs}</Typography>
                            </a>
                        </div>
                        :
                        <Typography style={{ color: '#00a099', fontSize: 16, fontWeight: 'bold' }} variant="caption" >{title}</Typography>
                }
            </>
        )
    }

    const GridTitle = ({ nWid, title }) => {
        return (
            <div style={{ width: nWid, backgroundColor: '#00a099' }}>
                <Typography style={{ marginLeft: 10, color: 'white', fontSize: 16, fontWeight: 'bold' }} variant="caption" >{title}</Typography>
            </div>
        )
    }

    const GridItem = ({ nWid, text, subtext = '' }) => {
        return (
            <div style={{ width: nWid, display: 'flex', flexDirection: 'column' }}>
                <Typography className={classes.gridItemStyle} variant="caption" >{text}</Typography>
                {
                    (subtext) &&
                    <Typography className={classes.gridSubItemStyle} variant="caption" >{subtext}</Typography>
                }
            </div>
        )
    }

    return (
        <div className={classes.wrapper}>

            <div className={classes.titlePanel}>
                <div className={classes.titleLabel}>
                    <FilterTitle title={`Carregamento Nº: ${carInfo.num ?? ''}`} />
                    <FilterTitle title={`Cliente: ${carInfo.cli ?? ''}`} />
                    <FilterTitle title={`Inclusão: ${carInfo.dat ?? ''}`} link={true} />
                    {
                        /*
                        <FilterTitle title={`Valor premiação: ${carInfo.val}`} />
                        <FilterTitle title={`Valor taxa: ${carInfo.vat}`} />
                        <FilterTitle title={`Valor tarifa: ${carInfo.taf}`} />
                        <FilterTitle title={`Valor total: ${carInfo.tot}`} />
                        (carInfo.nnf)
                        ?
                            <a download={`${carInfo.nnf}.pdf`} href={`data:application/octet-stream;base64,${carInfo.b64}`}>
                                <FilterTitle title={`Nº Nota fiscal: ${carInfo.nnf}`} />
                            </a>
                        :
                            <FilterTitle title={`Nº Nota fiscal: ${carInfo.nnf}`} />
                        */
                    }
                </div>
                <div>
                    <img style={{ height: 80, marginRight: 20 }} src={logoImg} alt="THCPay" />
                </div>
            </div>

            <div style={{ position: 'sticky', top: 80, display: 'flex', flexDirection: 'column', zIndex: 9 }}>
                <div style={{ display: 'flex' }}>
                    <GridTitle nWid={150} title='CPF' />
                    <GridTitle nWid={300} title='Nome' />
                    <GridTitle nWid={115} title='Valor (R$)' />
                    {
                        (carInfo.ban) ?
                            <>
                                <GridTitle nWid={250} title='Banco' />
                                <GridTitle nWid={80} title='Agência' />
                                <GridTitle nWid={80} title='Dígito' />
                                <GridTitle nWid={175} title='Nº Conta' />
                                <GridTitle nWid={80} title='Dígito' />
                                <GridTitle nWid={125} title='C.Poupança' />
                            </>
                            :
                            <>
                                <GridTitle nWid={200} title='ID/Proxy' />
                                <GridTitle nWid={150} title='Cartão' />
                                <GridTitle nWid={125} title='Novo cartão' />
                            </>
                    }
                    <GridTitle nWid={500} title='Observação' />
                </div>
            </div>

            <div style={{ position: 'relative', top: 80, display: 'flex', flexDirection: 'column' }}>
                {
                    favorecidos.map((item, idx) => {
                        return (
                            <div
                                key={item.num}
                                style={{
                                    display: 'flex',
                                    height: 60,
                                    backgroundColor: (idx % 2 === 0) ? '#f5f5f5' : null,
                                    padding: 5
                                }}
                            >
                                <GridItem nWid={150} text={item.cpf} />
                                <GridItem nWid={300} text={item.nom} />
                                <GridItem nWid={115} text={item.val} />
                                {
                                    (carInfo.ban) ?
                                        <>
                                            <GridItem nWid={250} text={item.ban} />
                                            <GridItem nWid={80} text={item.age} />
                                            <GridItem nWid={80} text={item.dag} />
                                            <GridItem nWid={175} text={item.nco} />
                                            <GridItem nWid={80} text={item.dco} />
                                            <GridItem nWid={125} text={item.cpo} />
                                        </>
                                        :
                                        <>
                                            <GridItem nWid={200} text={item.idp} />
                                            <GridItem nWid={150} text={item.car} />
                                            <GridItem nWid={125} text={item.nca} />
                                        </>
                                }
                                <GridItem nWid={500} text={item.obs} />
                            </div>
                        )
                    }
                    )
                }
            </div>

            <Hproalert
                type="error"
                message={msg}
                isOpen={openMsg}
                handleCloseWarning={setOpenMsg}
            />
            <Hpromainbuttons aButtons={[
                { name: 'Fechar', func: handleClose },
            ]} />
            <HproLoading loading={loading} />
        </div>
    )
}

export default DetalhaCarreg
