import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonBase, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    itemClass: {
        width: '100%',
        minHeight: '60px',
        display: 'flex',
        //paddingTop: 0,
        //marginRight: 12,
        paddingBottom: 10,
        paddingTop: 10,
        paddingRight: 4,
        paddingLeft: 4,
        flex: 1,
        flexDirection: 'column', 
        '&:hover': {
            backgroundColor: '#b2dfdb',
        },
        alignItems: 'center',
        justifyContent: 'center'
    },
    labelClass: {
        fontFamily: 'Noto Sans, sans-serif',
        fontSize: '0.8em',
        color: '#4fbfbb',
    },
    labelClass2: {
        fontFamily: 'Noto Sans, sans-serif',
        fontSize: '0.8em',
        color: '#4fbfbb',
    }
}));

const Hpromenuitem = ({ label, icon, itemSelect, select }) => {

    let aLab = label.split('|')

    const classes = useStyles()
    return (
        <>
            <ButtonBase
                className={classes.itemClass} onClick={itemSelect}>
                {icon}
                <Typography variant="caption" className={classes.labelClass} style={{color: (select === 1)? null : '#9e9e9e'}}>{aLab[0]}</Typography>
                {   
                    (aLab[1]) ?
                        <Typography variant="caption" className={classes.labelClass2} style={{color: (select === 1)? null : '#9e9e9e'}}>{aLab[1]}</Typography>
                    :
                        null
                }
            </ButtonBase>
        </>
    )
}

export default Hpromenuitem