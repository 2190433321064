import React, { useState, useEffect, useCallback } from 'react'
//import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import { process, delayedAlert } from '../../components/HproUtils'
import Hproalert from '../../components/Hproalert'
import Hpromainbuttons from '../../components/Hpromainbuttons'
import HproLoading from '../../components/HproLoading'
import Hproatencao from '../../components/Hproatencao'
import { Typography } from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import logoImg from '../../assets/logo.png'
import WebFav01 from './WebFav01'
import Ban01 from './Ban01'
import Ajd01 from './Ajd01'
import Fin01 from './Fin01'
import { formatValor, ctov } from '../../components/Rotinas'

//import { setArrayMenuSelect } from '../../controllers'
//setArrayMenuSelect([1, 0, 0, 0])

const ast = '*'

function useWindowSize() {
	const isClient = typeof window === 'object'
	const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })

	useEffect(() => {
		if (!isClient) {
			return false
		}

		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight
			})
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [isClient])

	return windowSize
}

const CarregManut = () => {

	const size = useWindowSize()

	const useStyles = makeStyles((theme) => ({
		wrapper: {
			flex: 1,
			height: size.height - 65,
		},
		titlePanel: {
			alignItems: 'center', //! => Remove
			// backgroundColor: '#f5f5f5',
			backgroundColor: 'white',
			display: 'flex',
			flexDirection: 'row',
			position: 'absolute',
			top: 65,
			width: size.width - 110,
			height: 80,
			zIndex: 10,
		},
		titleLabel: {
			textAlign: 'right',
			marginLeft: 20,
			display: 'flex',
			flexDirection: 'column'
		},
		titleLabelSecond: {
			textAlign: 'right',
			marginLeft: 20,
			display: 'flex',
			flexDirection: 'column-reverse'
		},
		titleDesc: {
			display: 'flex',
			flexDirection: 'column',
			marginLeft: 12,
			// flex: 1
		},
		titleDescSecond: {
			display: 'flex',
			flexDirection: 'column-reverse',
			marginLeft: 12,
			flex: 1
		},
		gridItemStyle: {
			marginLeft: 10,
			fontSize: 12, //! => 14
			fontWeight: 'bold',
			overflow: 'hidden',
		},
	}))

	const history = useHistory()
	const classes = useStyles()
	let { cli, pro } = useParams()

	const [fpa, setFpa] = useState('')
	const [ven, setVen] = useState('')
	const [loading, setLoading] = useState(false)
	const [openMsg, setOpenMsg] = useState(false)
	const [excOpen, setExcOpen] = useState(false)
	const [canOpen, setCanOpen] = useState(false)
	const [finOpen, setFinOpen] = useState(false)
	const [incOpen, setIncOpen] = useState(false)
	const [banOpen, setBanOpen] = useState(false)
	const [ajdOpen, setAjdOpen] = useState(false)
	const [emails, setEmails] = useState('')
	const [msg, setMsg] = useState('')
	const [alt, setAlt] = useState('_')
	const [del, setDel] = useState('_')
	const [selected, setSelected] = useState('')
	const [auxState, setAuxState] = useState('')
	const [carFavo, setCarFavo] = useState([])
	const [bancos, setBancos] = useState([])
	const [favorecidos, setFavorecidos] = useState([])
	const [carregamento, setCarregamento] = useState({
		cliInfo: '',
		proInfo: '',
		taxInfo: '',
		tedInfo: '',
	})

	const getCarregamento = useCallback(async () => {
		setLoading(true)
		try {
			try {

				const processResponse = await process('GETCARREGAMENTO', { nom: localStorage.getItem('TouchWeb_UserName'), cli: cli, pro: pro })
				const processResponseObj = JSON.parse(processResponse)
				const success = processResponseObj.success
				const content = processResponseObj.content

				if (success) {
					setCarregamento(content.carregamento)
					setFavorecidos(content.favorecidosCli)
					setBancos(content.bancos)

					setCarFavo(content.favorecidos.sort(function (a, b) {
						return a.nom < b.nom ? -1 : a.nom > b.nom ? 1 : 0;
					}))
					/*
					content.favorecidos.map(item => {
							console.log('2')
							setCarFavo(prev => prev.concat({
									cod: item.cod,
									cpf: item.cpf,
									nom: item.nom,
									val: item.val,
									idp: item.idp,
									car: item.car,
									nca: item.nca,
									ban: item.ban,
									age: item.age,
									dag: item.dag,
									nco: item.nco,
									dco: item.dco,
									cpo: item.cpo,
									obs: item.obs,
							}))
					})
					*/

				} else {
					setMsg(content.message)
					setOpenMsg(true)
				}

			} catch (error) {
				delayedAlert('Error', error.message)
			}
		} finally {
			setLoading(false)
		}
	}, [cli, pro])

	useEffect(() => {
		getCarregamento()
	}, [getCarregamento])

	const handleSave = async (bFin = false, aFav) => {
		setLoading(true)
		try {

			const parameters = {
				nom: localStorage.getItem('TouchWeb_UserName'),
				cli: cli,
				pro: pro,
				ema: emails,
				tax: carregamento.tax,
				ted: carregamento.ted,
				num: carregamento.num,
				hwebfav: JSON.stringify(aFav ? aFav : carFavo),
				ven: ven,
				fpa: fpa,
				fin: (bFin) ? 'Sim' : 'Não'
			}

			const processResponse = await process('SAVECARREGAMENTO', parameters)
			const processResponseObj = JSON.parse(processResponse)
			const success = processResponseObj.success
			const content = processResponseObj.content

			if (success) {
				if (bFin) {
					setFinOpen(false)
					setLoading(false)
					history.push("/main")
				} else {
					setAlt('_')
					getCarregamento()
				}
			} else {
				setMsg(content.message)
				setOpenMsg(true)
				setLoading(false)
			}

		} catch (error) {
			delayedAlert('Error', error.message)
			setLoading(false)
		}
	}

	const handleFinalizar = () => {
		handleSave(true)
	}

	const handleCancel = async () => {
		setLoading(true)
		try {

			if (!carregamento.num) {
				setMsg('Carregamento ainda não foi salvo, não é possível cancelá-lo')
				setOpenMsg(true)
				setLoading(false)
				return
			}

			const processResponse = await process('CANCELCARREGAMENTO', { nom: localStorage.getItem('TouchWeb_UserName'), num: carregamento.num })
			const processResponseObj = JSON.parse(processResponse)
			const success = processResponseObj.success
			const content = processResponseObj.content

			if (success) {
				setCanOpen(false)
				history.push("/main")
			} else {
				setCanOpen(false)
				setMsg(content.message)
				setOpenMsg(true)
				setLoading(false)
			}

		} catch (error) {
			delayedAlert('Error', error.message)
			setLoading(false)
		}
	}

	const handleInc = () => {
		if (!carregamento.num) {
			setMsg('Carregamento ainda não foi salvo, não é possível incluir um favorecido')
			setOpenMsg(true)
			setLoading(false)
			return
		}

		setIncOpen(true)
	}

	const handleExc = () => {
		let aDel = del.split('_')
		let cAux = alt
		let aFacTmp = carFavo

		for (let i = 1; i < aDel.length; i++) {
			aFacTmp = aFacTmp.filter(x => x.cod !== aDel[i])

			while (cAux.indexOf('_' + aDel[i] + '_') >= 0) {
				cAux = cAux.replace('_' + aDel[i] + '_', '_')
			}
		}

		setAlt(cAux)
		setDel('_')
		setSelected('')
		setCarFavo(aFacTmp)
		setExcOpen(false)
		handleSave(false, aFacTmp)
	}

	const handleAlt = (cAux) => {
		if (alt.indexOf('_' + cAux + '_') < 0) {
			setAlt(alt + cAux + '_')
		}
	}

	const handleDel = (cAux) => {
		let cDel = del
		if (del.indexOf('_' + cAux + '_') < 0) {
			setDel(del + cAux + '_')
		} else {
			cDel = cDel.replace('_' + cAux + '_', '_')
			setDel(cDel)
		}
	}

	const handleClose = () => {
		history.push("/main")
	}

	const FilterTitle = ({ title }) => {
		return ( //! => fontSize: 16
			<Typography style={{ color: '#00a099', fontSize: 14, fontWeight: 'bold' }} variant="caption" >{title}</Typography>
		)
	}

	const GridTitle = ({ nWid, title }) => {
		return ( //! => fontSize: 16
			<div style={{ width: nWid, backgroundColor: '#00a099' }}>
				<Typography style={{ marginLeft: 10, color: 'white', fontSize: 14, fontWeight: 'bold' }} variant="caption" >{title}</Typography>
			</div>
		)
	}

	const handleEdit = (nCod = 0, cFld = '') => {

		if (!nCod) {
			let aAux = selected.split(';')
			nCod = carFavo[aAux[0]].cod
			if (aAux[1] === '1') {
				cFld = 'cpf'
			} else if (aAux[1] === '2') {
				cFld = 'nom'
			} else if (aAux[1] === '3') {
				cFld = 'val'
			} else if (aAux[1] === '4') {
				(carregamento.ban) ? cFld = 'ban' : cFld = 'idp'
			} else if (aAux[1] === '5') {
				(carregamento.ban) ? cFld = 'age' : cFld = 'car'
			} else if (aAux[1] === '6') {
				(carregamento.ban) ? cFld = 'dag' : cFld = 'nca'
			} else if (aAux[1] === '7') {
				(carregamento.ban) ? cFld = 'nco' : cFld = 'obs'
			} else if (aAux[1] === '8') {
				cFld = 'dco'
			} else if (aAux[1] === '9') {
				cFld = 'cpo'
			} else if (aAux[1] === '10') {
				cFld = 'obs'
			}
		}

		if (cFld === 'cpf') {
			setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, cpf: auxState } : x))
		} else if (cFld === 'nom') {
			setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, nom: auxState } : x))
		} else if (cFld === 'val') {
			setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, val: formatValor(ctov(auxState)) } : x))
		} else if (cFld === 'car') {
			if (auxState.indexOf('*') >= 0) {
				//console.log()
			} else {
				setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, car: auxState } : x))
			}
		} else if (cFld === 'nca') {
			setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, nca: auxState } : x))
			if (auxState === 'Sim') {
				setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, car: '' } : x))
			}
		} else if (cFld === 'idp') {
			setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, idp: auxState } : x))
		} else if (cFld === 'ban') {
			setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, ban: auxState } : x))
		} else if (cFld === 'age') {
			setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, age: auxState } : x))
		} else if (cFld === 'dag') {
			setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, dag: auxState } : x))
		} else if (cFld === 'nco') {
			setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, nco: auxState } : x))
		} else if (cFld === 'dco') {
			setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, dco: auxState } : x))
		} else if (cFld === 'cpo') {
			setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, cpo: auxState } : x))
		} else if (cFld === 'obs') {
			setCarFavo(prev => prev.map(x => x.cod === nCod ? { ...x, obs: auxState } : x))
		} else {
			console.log(cFld)
		}
	}

	const handleKeyDown = (event, idx, fld) => {
		let nMax = (carregamento.ban) ? 11 : 7
		let bShift = event.shiftKey

		if (event.keyCode === 9) {
			handleEdit()
			if (bShift) {
				if (fld === 1) {
					setSelected(`${(idx - 1)};${nMax}`)
				} else {
					setSelected(`${idx};${(fld - 1)}`)
				}
			} else {
				if (fld === nMax) {
					setSelected(`${(idx + 1)};${1}`)
				} else {
					setSelected(`${idx};${(fld + 1)}`)
				}
			}
			event.preventDefault()
		} else if (event.keyCode === 38) { //KEY UP
			handleEdit()
			setSelected(`${(idx - 1)};${fld}`)
		} else if (event.keyCode === 40) { //KEY DOWN
			handleEdit()
			setSelected(`${(idx + 1)};${fld}`)
		} else if (event.keyCode === 107) { // +
			event.preventDefault()
			handleInc()
		} else if (event.keyCode === 109) { // -
			event.preventDefault()
			setExcOpen(true)
		}
	}

	const handleBanSelect = (cBan) => {
		let nIdx = selected.split(';')[0]
		setCarFavo(prev => prev.map(x => x.cod === carFavo[nIdx].cod ? { ...x, ban: cBan } : x))
		handleAlt(carFavo[nIdx].cod)
		setBanOpen(false)
	}

	return (
		<div className={classes.wrapper}>

			<div className={classes.titlePanel}>
				<div className={classes.titleLabel}>
					<FilterTitle title={'Cliente:'} />
					<FilterTitle title={'Produto:'} />
					<FilterTitle title={'Taxa ADM:'} />
				</div>
				<div className={classes.titleDesc}>
					<FilterTitle title={carregamento.cliInfo} />
					<FilterTitle title={carregamento.proInfo} />
					<FilterTitle title={carregamento.taxInfo + '%'} />
				</div>
				<div className={classes.titleLabelSecond}>
					{carregamento.ban && <FilterTitle title='Tarifa TED:' />}
				</div>
				<div className={classes.titleDescSecond}>
					{carregamento.ban && <FilterTitle title={'R$ ' + carregamento.tedInfo} />}
				</div>
				<div>
					<img style={{ height: 80, marginRight: 20 }} src={logoImg} alt="THCPay" />
				</div>
			</div>

			<div style={{ position: 'sticky', top: 80, display: 'flex', flexDirection: 'column', zIndex: 9 }}>
				<div style={{ display: 'flex' }}>
					<GridTitle nWid={150} title='CPF' />
					<GridTitle nWid={300} title='Nome' />
					<GridTitle nWid={115} title='Valor (R$)' />
					{
						(carregamento.ban) ?
							<>
								<GridTitle nWid={250} title='Banco' />
								<GridTitle nWid={80} title='Agência' />
								<GridTitle nWid={80} title='Dígito' />
								<GridTitle nWid={175} title='Nº Conta' />
								<GridTitle nWid={80} title='Dígito' />
								<GridTitle nWid={125} title='C.Poupança' />
							</>
							:
							<>
								<GridTitle nWid={200} title='ID/Proxy' />
								<GridTitle nWid={150} title='Cartão' />
								<GridTitle nWid={125} title='Novo cartão' />
							</>
					}
					<GridTitle nWid={500} title='Observação' />
				</div>
			</div>

			<div style={{ position: 'relative', top: 80, display: 'flex', flexDirection: 'column' }}>
				{
					carFavo.map((item, idx) => {
						return (
							<div
								key={idx}
								style={{
									display: 'flex',
									height: 40, //! => 50
									backgroundColor:
										(del.indexOf('_' + item.cod + '_') >= 0) ? '#998a08' :
											(alt.indexOf('_' + item.cod + '_') >= 0) ? 'red' : (idx % 2 === 0) ? '#f5f5f5' : null
								}}
							>
								{
									(selected === `${idx};1`) ?
										<InputBase
											style={{ width: 150, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
											inputProps={{ 'aria-label': 'naked' }}
											onFocus={(e) => { (item.cpf) ? setAuxState(item.cpf) : setAuxState('') }}
											onChange={(e) => { handleAlt(item.cod); setAuxState(e.target.value.slice(0, 14)) }}
											onBlur={() => handleEdit(item.cod, 'cpf')}
											value={auxState}
											autoFocus
											onKeyDown={(e) => handleKeyDown(e, idx, 1)}
											onDoubleClick={() => handleDel(item.cod)}
										/>
										:
										<div
											style={{ width: 150, display: 'flex', alignItems: 'center' }}
											onClick={() => setSelected(`${idx};1`)}
										>
											<Typography className={classes.gridItemStyle} variant="caption" >{item.cpf}</Typography>
										</div>
								}
								{
									(selected === `${idx};2`) ?
										<InputBase
											style={{ width: 300, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
											inputProps={{ 'aria-label': 'naked' }}
											onFocus={(e) => { (item.nom) ? setAuxState(item.nom) : setAuxState('') }}
											onChange={(e) => { handleAlt(item.cod); setAuxState(e.target.value.slice(0, 60)) }}
											onBlur={() => handleEdit(item.cod, 'nom')}
											value={auxState}
											autoFocus
											onKeyDown={(e) => handleKeyDown(e, idx, 2)}
										/>
										:
										<div
											style={{ width: 300, display: 'flex', alignItems: 'center' }}
											onClick={() => setSelected(`${idx};2`)}
										>
											<Typography className={classes.gridItemStyle} variant="caption" >{item.nom}</Typography>
										</div>
								}

								{
									(selected === `${idx};3`) ?
										<InputBase
											style={{ width: 115, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
											inputProps={{ 'aria-label': 'naked' }}
											onFocus={(e) => { (item.val) ? setAuxState(item.val) : setAuxState('') }}
											onChange={(e) => { handleAlt(item.cod); setAuxState(e.target.value.slice(0, 14)) }}
											onBlur={() => handleEdit(item.cod, 'val')}
											value={auxState}
											autoFocus
											onKeyDown={(e) => handleKeyDown(e, idx, 3)}
										/>
										:
										<div
											style={{ width: 115, display: 'flex', alignItems: 'center' }}
											onClick={() => setSelected(`${idx};3`)}
										>
											<Typography className={classes.gridItemStyle} variant="caption" >{item.val}</Typography>
										</div>
								}
								{
									(carregamento.ban) ?
										// Se for bancário
										<>
											{
												(selected === `${idx};4`) ?
													<InputBase
														style={{ width: 250, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
														inputProps={{ 'aria-label': 'naked' }}
														onFocus={(e) => { (item.ban) ? setAuxState(item.ban) : setAuxState('') }}
														onChange={(e) => { handleAlt(item.cod); setAuxState(e.target.value) }}
														onBlur={() => handleEdit(item.cod, 'ban')}
														value={auxState}
														autoFocus
														onKeyDown={(e) => handleKeyDown(e, idx, 4)}
														readOnly={true}
														//onDoubleClick={() => {handleAlt(item.cod);setAuxState((auxState === 'Sim') ? 'Não' : 'Sim' )}}
														onDoubleClick={() => setBanOpen(true)}
													/>
													:
													<div
														style={{ width: 250, display: 'flex', alignItems: 'center' }}
														onClick={() => setSelected(`${idx};4`)}
													>
														<Typography className={classes.gridItemStyle} variant="caption" >{item.ban}</Typography>
													</div>
											}
											{
												(selected === `${idx};5`) ?
													<InputBase
														style={{ width: 80, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
														inputProps={{ 'aria-label': 'naked' }}
														onFocus={(e) => { (item.age) ? setAuxState(item.age) : setAuxState('') }}
														onChange={(e) => { handleAlt(item.cod); setAuxState(e.target.value.slice(0, 4)) }}
														onBlur={() => handleEdit(item.cod, 'age')}
														value={auxState}
														autoFocus
														onKeyDown={(e) => handleKeyDown(e, idx, 5)}
													/>
													:
													<div
														style={{ width: 80, display: 'flex', alignItems: 'center' }}
														onClick={() => setSelected(`${idx};5`)}
													>
														<Typography className={classes.gridItemStyle} variant="caption" >{item.age}</Typography>
													</div>
											}
											{
												(selected === `${idx};6`) ?
													<InputBase
														style={{ width: 80, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
														inputProps={{ 'aria-label': 'naked' }}
														onFocus={(e) => { (item.dag) ? setAuxState(item.dag) : setAuxState('') }}
														onChange={(e) => { handleAlt(item.cod); setAuxState(e.target.value.slice(0, 1)) }}
														onBlur={() => handleEdit(item.cod, 'dag')}
														value={auxState}
														autoFocus
														onKeyDown={(e) => handleKeyDown(e, idx, 6)}
													/>
													:
													<div
														style={{ width: 80, display: 'flex', alignItems: 'center' }}
														onClick={() => setSelected(`${idx};6`)}
													>
														<Typography className={classes.gridItemStyle} variant="caption" >{item.dag}</Typography>
													</div>
											}
											{
												(selected === `${idx};7`) ?
													<InputBase
														style={{ width: 175, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
														inputProps={{ 'aria-label': 'naked' }}
														onFocus={(e) => { (item.nco) ? setAuxState(item.nco) : setAuxState('') }}
														onChange={(e) => { handleAlt(item.cod); setAuxState(e.target.value.slice(0, 15)) }}
														onBlur={() => handleEdit(item.cod, 'nco')}
														value={auxState}
														autoFocus
														onKeyDown={(e) => handleKeyDown(e, idx, 7)}
													/>
													:
													<div
														style={{ width: 175, display: 'flex', alignItems: 'center' }}
														onClick={() => setSelected(`${idx};7`)}
													>
														<Typography className={classes.gridItemStyle} variant="caption" >{item.nco}</Typography>
													</div>
											}
											{
												(selected === `${idx};8`) ?
													<InputBase
														style={{ width: 80, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
														inputProps={{ 'aria-label': 'naked' }}
														onFocus={(e) => { (item.dco) ? setAuxState(item.dco) : setAuxState('') }}
														onChange={(e) => { handleAlt(item.cod); setAuxState(e.target.value.slice(0, 1)) }}
														onBlur={() => handleEdit(item.cod, 'dco')}
														value={auxState}
														autoFocus
														onKeyDown={(e) => handleKeyDown(e, idx, 8)}
													/>
													:
													<div
														style={{ width: 80, display: 'flex', alignItems: 'center' }}
														onClick={() => setSelected(`${idx};8`)}
													>
														<Typography className={classes.gridItemStyle} variant="caption" >{item.dco}</Typography>
													</div>
											}
											{
												(selected === `${idx};9`) ?
													<InputBase
														style={{ width: 125, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
														inputProps={{ 'aria-label': 'naked' }}
														onFocus={(e) => { (item.cpo) ? setAuxState(item.cpo) : setAuxState('') }}
														onChange={(e) => { handleAlt(item.cod); setAuxState(e.target.value.slice(0, 3)) }}
														onBlur={() => handleEdit(item.cod, 'cpo')}
														value={auxState}
														autoFocus
														onKeyDown={(e) => handleKeyDown(e, idx, 9)}
														readOnly={true}
														onDoubleClick={() => { handleAlt(item.cod); setAuxState((auxState === 'Sim') ? 'Não' : 'Sim') }}
													/>
													:
													<div
														style={{ width: 125, display: 'flex', alignItems: 'center' }}
														onClick={() => setSelected(`${idx};9`)}
													>
														<Typography className={classes.gridItemStyle} variant="caption" >{item.cpo}</Typography>
													</div>
											}
											{
												(selected === `${idx};10`) ?
													<InputBase
														style={{ width: 500, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
														inputProps={{ 'aria-label': 'naked' }}
														onFocus={(e) => { (item.obs) ? setAuxState(item.obs) : setAuxState('') }}
														onChange={(e) => { handleAlt(item.cod); setAuxState(e.target.value) }}
														onBlur={() => handleEdit(item.cod, 'obs')}
														value={auxState}
														autoFocus
														onKeyDown={(e) => handleKeyDown(e, idx, 10)}
													/>
													:
													<div
														style={{ width: 500, display: 'flex', alignItems: 'center' }}
														onClick={() => setSelected(`${idx};10`)}
													>
														<Typography className={classes.gridItemStyle} variant="caption" >{item.obs}</Typography>
													</div>
											}
										</>
										// Se for cartão
										:
										<>
											{
												(selected === `${idx};4`) ?
													<InputBase
														style={{ width: 200, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
														inputProps={{ 'aria-label': 'naked' }}
														onFocus={(e) => { (item.idp) ? setAuxState(item.idp) : setAuxState('') }}
														onChange={(e) => { handleAlt(item.cod); setAuxState(e.target.value.slice(0, 40)) }}
														onBlur={() => handleEdit(item.cod, 'idp')}
														value={auxState}
														autoFocus
														onKeyDown={(e) => handleKeyDown(e, idx, 4)}
													/>
													:
													<div
														style={{ width: 200, display: 'flex', alignItems: 'center' }}
														onClick={() => setSelected(`${idx};4`)}
													>
														<Typography className={classes.gridItemStyle} variant="caption" >{item.idp}</Typography>
													</div>
											}
											{
												(selected === `${idx};5`) ?
													<InputBase
														style={{ width: 150, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
														inputProps={{ 'aria-label': 'naked' }}
														onFocus={(e) => {
															(item.car) ?
																(item.car.length > 8)
																	?
																	setAuxState(`${item.car.substring(0, 4)}${ast.repeat(item.car.length - 8)}${item.car.substring(item.car.length - 4, item.car.length)}`)
																	:
																	setAuxState(item.car)
																:
																setAuxState('')
														}}
														onChange={(e) => {
															handleAlt(item.cod)
															setAuxState(e.target.value.slice(0, 30))
														}}
														onBlur={() => handleEdit(item.cod, 'car')}
														value={auxState}
														autoFocus
														onKeyDown={(e) => handleKeyDown(e, idx, 5)}
													/>
													:
													<div
														style={{ width: 150, display: 'flex', alignItems: 'center' }}
														onClick={() => setSelected(`${idx};5`)}
													>
														<Typography className={classes.gridItemStyle} variant="caption" >
															{
																(item.car)
																	?
																	(item.car.length > 8)
																		?
																		`${item.car.substring(0, 4)}${ast.repeat(item.car.length - 8)}${item.car.substring(item.car.length - 4, item.car.length)}`
																		:
																		item.car
																	:
																	''
															}
														</Typography>
													</div>
											}
											{
												(selected === `${idx};6`) ?
													<InputBase
														style={{ width: 125, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
														inputProps={{ 'aria-label': 'naked' }}
														onFocus={(e) => { (item.nca) ? setAuxState(item.nca) : setAuxState('') }}
														onChange={(e) => { handleAlt(item.cod); setAuxState(e.target.value.slice(0, 3)) }}
														onBlur={() => handleEdit(item.cod, 'nca')}
														value={auxState}
														autoFocus
														onKeyDown={(e) => handleKeyDown(e, idx, 6)}
														readOnly={true}
														onDoubleClick={() => { handleAlt(item.cod); setAuxState((auxState === 'Sim') ? 'Não' : 'Sim') }}
													/>
													:
													<div
														style={{ width: 125, display: 'flex', alignItems: 'center' }}
														onClick={() => setSelected(`${idx};6`)}
													>
														<Typography className={classes.gridItemStyle} variant="caption" >{item.nca}</Typography>
													</div>
											}
											{
												(selected === `${idx};7`) ?
													<InputBase
														style={{ width: 500, display: 'flex', alignItems: 'center', border: 1, borderStyle: 'dotted' }}
														inputProps={{ 'aria-label': 'naked' }}
														onFocus={(e) => { (item.obs) ? setAuxState(item.obs) : setAuxState('') }}
														onChange={(e) => { handleAlt(item.cod); setAuxState(e.target.value) }}
														onBlur={() => handleEdit(item.cod, 'obs')}
														value={auxState}
														autoFocus
														onKeyDown={(e) => handleKeyDown(e, idx, 7)}
													/>
													:
													<div
														style={{ width: 500, display: 'flex', alignItems: 'center' }}
														onClick={() => setSelected(`${idx};7`)}
													>
														<Typography className={classes.gridItemStyle} variant="caption" >{item.obs}</Typography>
													</div>
											}
										</>
								}
							</div>
						)
					}
					)
				}
			</div>

			<Hproalert
				type="error"
				message={msg}
				isOpen={openMsg}
				handleCloseWarning={setOpenMsg}
			/>
			<Hpromainbuttons aButtons={[
				{ name: 'Salvar', func: handleSave },
				{ name: 'Finalizar', func: () => setFinOpen(true) },
				{ name: 'Cancelar', func: () => setCanOpen(true) },
				{ name: 'Incluir', func: handleInc },
				{
					name: 'Excluir', func: () => {
						if (!del.split('_').filter((item) => item.length).length) {
							setMsg('Nenhum favorecido foi selecionado')
							setOpenMsg(true)
							return
						} else {
							setExcOpen(true)
						}
					}
				},
				{ name: 'Ajuda', func: () => setAjdOpen(true) },
				{ name: 'Fechar', func: handleClose },
			]} />
			<Hproatencao
				title="Confirma a exclusão dos favorecidos selecionados?"
				isOpen={excOpen}
				onSim={handleExc}
				onNao={() => setExcOpen(false)}
				buttons={2}
			/>
			<Hproatencao
				title="Confirma o cancelamento do carregamento?"
				isOpen={canOpen}
				onSim={handleCancel}
				onNao={() => setCanOpen(false)}
				buttons={2}
			/>
			<Fin01 open={finOpen} setOpen={setFinOpen} handleCheck={handleFinalizar} itens={carFavo} favCount={favorecidos} tax={carregamento.taxInfo} ted={carregamento.tedInfo} ven={ven} setVen={setVen} fpa={fpa} setFpa={setFpa} emails={emails} setEmails={setEmails} />
			<WebFav01 open={incOpen} setOpen={setIncOpen} numero={carregamento.num} banco={carregamento.ban} bancos={bancos} itens={favorecidos} setLoading={setLoading} setMsg={setMsg} setOpenMsg={setOpenMsg} getCarregamento={getCarregamento} />
			<Ban01 open={banOpen} setOpen={setBanOpen} select={handleBanSelect} itens={bancos} />
			<Ajd01 open={ajdOpen} setOpen={setAjdOpen} />
			<HproLoading loading={loading} />
		</div>
	)
}

export default CarregManut
