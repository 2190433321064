import React, { useState, useEffect } from 'react'

import { setArrayMenuSelect } from '../../controllers'
import { setToggleMenuLateralFunction } from './controllers'

import { useHistory } from 'react-router-dom'

import Hpromenuitem from '../../components/Hpromenuitem'
import Hprosearch from '../../components/Hprosearch'
import Hproalert from '../../components/Hproalert'
import { process, delayedAlert } from '../../components/HproUtils'
import HproLoading from '../../components/HproLoading'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton, List } from '@material-ui/core'

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import PostAddIcon from '@material-ui/icons/PostAdd'
import HomeIcon from '@material-ui/icons/Home'
import MenuIcon from '@material-ui/icons/Menu'
import LockIcon from '@material-ui/icons/Lock';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Cli01 from '../Carregamentos/Cli01'
import Pro01 from '../Carregamentos/Pro01'
import Pla01 from '../Carregamentos/Pla01'


  // Hook
  function useWindowSize() {
    const isClient = typeof window === 'object'
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })

    useEffect(() => {
      if (!isClient) {
        return false;
      }

      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [isClient]);

    return windowSize;
  }


const Main = ({ children }) => {
    const [selected, setSelected] = useState([])
    const [toggleMenuLateral, setToggleMenuLateral] = useState(true)

    const [msg, setMsg] = useState({
        open: false,
        type: '',
        text: ''
    })

    const [isLoading, setIsLoading] = useState(false)

    // Arrays para os modais (Montar os grids)
    const [clientes, setClientes] = useState([])
    const [produtos, setProdutos] = useState([])

    // Modais abertos/fechados
    const [cliOpen, setCliOpen] = useState(false)
    const [proOpen, setProOpen] = useState(false)
    const [plaOpen, setPlaOpen] = useState(false)

    // Registros selecionados nos modais
    const [cliSelected, setCliSelected] = useState(0)
    const [proSelected, setProSelected] = useState(0)
    const [cliRaz, setCliRaz] = useState('')
    //const [proSelected, setProSelected] = useState(0)

    const [consulta, setConsulta] = useState(false)

    const size = useWindowSize()

    setArrayMenuSelect(setSelected)
    setToggleMenuLateralFunction(toggleMenuLateral)

    const usuario = `Usuário: ${localStorage.getItem('TouchWeb_UserName')}`

    const useStyles = makeStyles(theme => ({
        sectionDesktop: {
            display: 'flex',
        },
        userInfo: {
            display: 'flex',
            flexDirection: 'column',
            marginRight: '10px',
        },
        menuIcons: {
            color: 'white',
            marginLeft: '5px'
        },
        childrenRenderer: {
            flex: 1,
            display: 'flex',
            height: size.height - 65,
        },
        childrenStyle: {
            flex: 1,
            display: 'flex',
            maxHeight: size.height - 65,
            overflowX: 'auto',
            overflowY: 'auto',
        },
        listClass: {
            width: 90 ,
            backgroundColor: '#f5f5f5',
            borderRightWidth: '1',
        },
        menuDiv: {
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
        },
    }));
    const classes = useStyles()
    const history = useHistory()

    const midea680 = useMediaQuery('(max-width:680px)');
    const midea600 = useMediaQuery('(max-width:600px)');

    const closeMsg = () => {
        setMsg({
            ...msg,
            open: false
        })
    }

    const returnToLogin = () => {
        localStorage.removeItem('TouchWeb_UserName')
        history.push("/")
    }

    const select = (idx) => {
        let aSelects = [0, 0, 0, 0]
        aSelects[idx] = 1
        setSelected(aSelects)
    }

    const checkCarregamentoPendente = async (nCli, nPro) => {
        setIsLoading(true)
        try {
            try {

                const processResponse = await process('CARREGAMENTOPENDENTE', { cli: nCli, pro: nPro })
                const processResponseObj = JSON.parse(processResponse)
                const success = processResponseObj.success

                // se der sucesso é pq tem carregamento pendente se não não tem
                if (success) {
                    history.push(`/carregmanut/${nCli}/${nPro}`)
                } else {
                    setPlaOpen(true)
                }

            } catch (error) {
                delayedAlert('Error', error.message);
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handleCliSelect = (nCli,cRaz) => {
        setCliSelected(nCli)
        setCliRaz(cRaz)
        setCliOpen(false)
        if (consulta) {
            history.push(`/consultacarreg/${nCli}`)
        } else {
            setProOpen(true)
        }
    }

    const handleProSelect = (nPro) => {
        setProSelected(nPro)
        setProOpen(false)
        checkCarregamentoPendente(cliSelected,nPro)
        //history.push(`/carregmanut/${cliSelected}/${nPro}`)
    }

    const handlePlaSelect = () => {
        setPlaOpen(false)
        history.push(`/carregmanut/${cliSelected}/${proSelected}`)
    }

    const handleAdd = async ( isConsulta ) => {
        setIsLoading(true)
        try {
            try {

                const processResponse = await process('GETCLIENTESUSU', { nom: localStorage.getItem('TouchWeb_UserName') })
                const processResponseObj = JSON.parse(processResponse)
                const success = processResponseObj.success
                const content = processResponseObj.content

                if (success) {
                    setClientes(content.hcli)
                    setProdutos(content.hprocli)
                    setConsulta(isConsulta)

                    if (isConsulta) {
                        if (content.hcli.length === 1) {
                            history.push(`/consultacarreg/${content.hcli[0].cli}`)
                        } else {
                            setClientes(prev => prev.concat({
                                cli: '999999',
                                raz: 'TODOS',
                                cpx: 'xx.xxx.xxx/xxxx-xx',
                                cid: '',
                                est: '',
                            }))
                            setCliOpen(true)
                        }
                    } else {
                        if (content.hcli.length === 1) {
                            if (content.hprocli.length === 1) {
                                //history.push(`/carregmanut/${content.hcli[0].cli}/${content.hprocli[0].pro}`)
                                setCliSelected(content.hcli[0].cli)
                                setProSelected(content.hprocli[0].pro)
                                checkCarregamentoPendente(content.hcli[0].cli,content.hprocli[0].pro)
                            } else {
                                setCliSelected(content.hcli[0].cli)
                                setProOpen(true)
                            }
                        } else {
                            setCliOpen(true)
                        }
                    }
                } else {
                    //setMsg(content.message)
                    //setOpenMsg(true)
                }

            } catch (error) {
                delayedAlert('Error', error.message);
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <nav style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div style={{ backgroundColor: '#00a099', display: 'flex', height: '65px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                {
                    (midea680) ?
                        <MenuIcon onClick={() => { setToggleMenuLateral(!toggleMenuLateral) }} style={{ color: 'white', marginLeft: '27px', mode: 'no-cors' }} />
                        :
                        <div className={classes.userInfo}>
                            <Typography style={{ marginLeft: '12px', color: 'white' }} variant="h6" >THCPAY</Typography>
                            <Typography style={{ marginLeft: '12px', color: 'white' }} variant="subtitle2"> {usuario}</Typography>
                        </div>
                }
                {
                    (midea600) ?
                        <div className={classes.userInfo}>
                            <Typography style={{ marginLeft: '12px', color: 'white' }} variant="h6" >THCPAY</Typography>
                            <Typography style={{ marginLeft: '12px', color: 'white' }} variant="subtitle2"> {usuario}</Typography>
                        </div>
                        :
                        <div className={classes.sectionDesktop}><Hprosearch /></div>
                }
                <div style={{ marginRight: '10px' }}>
                    <IconButton
                        aria-label="Sair"
                        color="secondary"
                        className={classes.menuIcons}
                        onClick={() => returnToLogin()}
                    >
                        <MeetingRoomIcon />
                    </IconButton>
                </div>
            </div>
            {
                (toggleMenuLateral) ?
                    <div className={classes.childrenRenderer}>
                        <List className={classes.listClass}>
                            <Hpromenuitem itemSelect={() => { history.push("/main"); select(0) }} select={selected[0]} icon={<HomeIcon   style={{ color: (selected[0] === 1) ? "#4fbfbb" : '#9e9e9e' }} />} label="Início" />
                            <Hpromenuitem itemSelect={() => { select(1); handleAdd(false) }} select={selected[1]} icon={<PostAddIcon style={{ color: (selected[1] === 1) ? "#4fbfbb" : '#9e9e9e' }} />} label="Solicitar|carregamento" />
                            <Hpromenuitem itemSelect={() => { select(2); handleAdd(true) }} select={selected[2]} icon={<FormatListBulletedIcon style={{ color: (selected[2] === 1) ? "#4fbfbb" : '#9e9e9e' }} />} label="Consultar|carregamentos" />
                            <Hpromenuitem itemSelect={() => { history.push("/chgpassword"); select(3) }} select={selected[3]} icon={<LockIcon style={{ color: (selected[3] === 1) ? "#4fbfbb" : '#9e9e9e' }} />} label="Trocar|senha" />
                            <Hpromenuitem itemSelect={() => { returnToLogin() }} icon={<MeetingRoomIcon style={{ color: '#9e9e9e' }} />} label="Sair" />
                        </List>
                        <div className={classes.childrenStyle}>
                            {children}
                        </div>
                    </div>
                :
                    <div className={classes.childrenStyle}>
                        {children}
                    </div>
            }
            <Cli01 open={cliOpen} setOpen={setCliOpen} itens={clientes} select={handleCliSelect} />
            <Pro01 open={proOpen} setOpen={setProOpen} itens={produtos} select={handleProSelect} cliente={cliSelected} cliTit={cliRaz} />
            <Pla01 open={plaOpen} setOpen={setPlaOpen} select={handlePlaSelect} setMsg={setMsg} cli={cliSelected} pro={proSelected} />
            <Hproalert
                type={msg.type}
                message={msg.text}
                isOpen={msg.open}
                handleCloseWarning={closeMsg}
            />
            <HproLoading loading={isLoading}/>
        </nav>
    )
}

export default Main
