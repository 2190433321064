export const menuClientes = [
    {
        id: 1,
        title: 'SOLICITAR CARREGAMENTO'
    },
    {
        id: 2,
        title: 'CONSULTAR CARREGAMENTOS',
        //link: '/clienteconsulta',
        //searchtext: 'Consulta cadastros efetivados',
    },
    {
        id: 3,
        title: 'TROCAR SENHA',
        link: '/chgpassword',
        searchtext: 'Trocar senha',
        menuColor: 3,
    },
]

let navMenus = []
let nI = 0

for(nI = 0;nI < menuClientes.length;nI++) {
    if(menuClientes[nI].searchtext) {
        navMenus.push({
            searchtext: menuClientes[nI].searchtext,
            link: menuClientes[nI].link,
            menuColor: menuClientes[nI].menuColor
        })
    }
}

export default navMenus