import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

const HproLoading = ({ loading }) => {

    return (
        <Backdrop style={{ zIndex: 9999, color: '#fff' }} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default HproLoading