/*
===============================
||     HPRO MAIN BUTTONS     ||
===============================

---------------------------------------
Criado em 12/06/2020 por IP
Atualizações:
    -
---------------------------------------

- Botões fixos de pé no canto da tela, precisa passar um array em "aButtons" (exemplos abaixo) e "form" deixar vazio
ou colcoar submit se for um formulário linkado com Formik.

Seguem exemplos:

- Como usar:
    * Propriedada form: 
    
    Quando for um formulário para submit (por exemplo inclusão de cliente) passar como "submit"
    e o array de botões passar dessa forma:
        - buttons = [{ name: 'Incluir', func: null } , ..qualquer outro botão]
                            (** BOTÃO SUBMIT FUNÇÃO = NULL **)       

    Se for um formulário normal deixar a propriedade form vazia e passar o array de botões dessa forma:
    const botoes = [
        {
            name: 'Incluir',
            func: handleIncluir,  ---> FUNÇÃO DO FORMUÁRIO RAÍZ
        },
        {
            name: 'Alterar',
            func: handleAlterar,  ---> FUNÇÃO DO FORMUÁRIO RAÍZ
        }, .....
    ]

- Names aceitos:
    - Incluir: botão com um +
    - Alterar: botão com uma canetinha
    - Consultar: botão com uma lupa
    - Excluir: botão com um -
    - Fechar: botão com um X
    - Direitos: Botão com um sinal de correto
*/

import React from 'react'
import Fab from '@material-ui/core/Fab'
import EditIcon from '@material-ui/icons/Edit'
import RemoveIcon from '@material-ui/icons/Remove'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import SaveIcon from '@material-ui/icons/Save'
import FolderIcon from '@material-ui/icons/Folder'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import PrintIcon from '@material-ui/icons/Print'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import CloudDownload from '@material-ui/icons/CloudDownload'

const Hpromainbuttons = ({ aButtons, form = "button", lang = 'br' }) => {

    return (
        <div style={{ flex: '1', flexDirection: 'row', position: 'fixed', bottom: "20px", right: "10px" }}>
            {
                aButtons.map((button, idx) => {
                    return (
                        <div key={idx}>
                            {
                                (button.name === 'Incluir') ?
                                    <div style={{ marginTop: "3px" }} >
                                        <Tooltip title="Adicionar" placement="top-start">
                                            <Fab
                                                color="primary"
                                                aria-label="Adicionar"
                                                size="small"
                                                type={form}
                                                onClick={() => (form === "submit") ? console.log('submit') : button.func()}
                                            >
                                                <AddIcon />
                                            </Fab>
                                        </Tooltip>
                                    </div>
                                    : (button.name === 'Salvar') ?
                                        <div style={{ marginTop: "3px" }} >
                                            <Tooltip title={(lang === 'br' ? 'Gravar' : 'Save')} placement="top-start">
                                                <Fab
                                                    color="primary"
                                                    aria-label={(lang === 'br') ? 'Gravar' : 'Save'}
                                                    size="small"
                                                    type={form}
                                                    onClick={() => (form === "submit") ? console.log('submit') : button.func()}
                                                >
                                                    <SaveIcon />
                                                </Fab>
                                            </Tooltip>
                                        </div>
                                        : (button.name === 'Alterar') ?
                                            <div style={{ marginTop: "3px" }}>
                                                <Tooltip title={(lang === 'br' ? 'Editar' : 'Edit')} placement="top-start">
                                                    <Fab
                                                        color="primary"
                                                        aria-label="Editar"
                                                        size="small"
                                                        type={form}
                                                        onClick={() => (form === "submit") ? console.log('submit') : button.func()}
                                                    >
                                                        <EditIcon />
                                                    </Fab>
                                                </Tooltip>
                                            </div>
                                            : (button.name === 'Consultar') ?
                                                <div style={{ marginTop: "3px" }}>
                                                    <Tooltip title={(lang === 'br' ? 'Consultar' : 'Browse')} placement="top-start">
                                                        <Fab
                                                            color="primary"
                                                            aria-label="Consultar"
                                                            size="small"
                                                            type={form}
                                                            onClick={() => (form === "submit") ? console.log('submit') : button.func()}
                                                        >
                                                            <SearchIcon />
                                                        </Fab>
                                                    </Tooltip>
                                                </div>
                                                : (button.name === 'Excluir') ?
                                                    <div style={{ marginTop: "3px" }}>
                                                        <Tooltip title="Remover" placement="top-start">
                                                            <Fab
                                                                color="primary"
                                                                aria-label="Remover"
                                                                size="small"
                                                                type={form}
                                                                onClick={() => (form === "submit") ? console.log('submit') : button.func()}
                                                            >
                                                                <RemoveIcon />
                                                            </Fab>
                                                        </Tooltip>
                                                    </div>
                                                    : (button.name === 'Fechar') ?
                                                        <div style={{ marginTop: "3px" }}>
                                                            <Tooltip title={(lang === 'br' ? 'Fechar' : 'Close')} placement="top-start">
                                                                <Fab
                                                                    color="primary"
                                                                    aria-label="Fechar"
                                                                    size="small"
                                                                    type="button"
                                                                    onClick={() => button.func()}
                                                                >
                                                                    <CloseIcon />
                                                                </Fab>
                                                            </Tooltip>
                                                        </div>
                                                        : (button.name === 'Direitos' || button.name === 'Finalizar') ?
                                                            <div style={{ marginTop: "3px" }}>
                                                                <Tooltip title={button.name} placement="top-start">
                                                                    <Fab
                                                                        color="primary"
                                                                        aria-label={button.name}
                                                                        size="small"
                                                                        type={form}
                                                                        onClick={() => button.func()}
                                                                    >
                                                                        <CheckIcon />
                                                                    </Fab>
                                                                </Tooltip>
                                                            </div>
                                                            : (button.name === 'Anexos') ?
                                                                <div style={{ marginTop: "3px" }}>
                                                                    <Tooltip title="Anexos" placement="top-start">
                                                                        <Fab
                                                                            color="primary"
                                                                            aria-label="Anexos"
                                                                            size="small"
                                                                            type={form}
                                                                            onClick={() => button.func()}
                                                                        >
                                                                            <FolderIcon />
                                                                        </Fab>
                                                                    </Tooltip>
                                                                </div>
                                                                : (button.name === 'Itens') ?
                                                                    <div style={{ marginTop: "3px" }}>
                                                                        <Tooltip title={(lang === 'br' ? 'Itens' : 'Items')} placement="top-start">
                                                                            <Fab
                                                                                color="primary"
                                                                                aria-label="Itens"
                                                                                size="small"
                                                                                type={form}
                                                                                onClick={() => button.func()}
                                                                            >
                                                                                <FormatListNumberedIcon />
                                                                            </Fab>
                                                                        </Tooltip>
                                                                    </div>
                                                                    : (button.name === 'Imprimir') ?
                                                                        <div style={{ marginTop: "3px" }}>
                                                                            <Tooltip title={(lang === 'br' ? 'Imprimir' : 'Print')} placement="top-start">
                                                                                <Fab
                                                                                    color="primary"
                                                                                    aria-label="Imprimir"
                                                                                    size="small"
                                                                                    type={form}
                                                                                    onClick={() => button.func()}
                                                                                >
                                                                                    <PrintIcon />
                                                                                </Fab>
                                                                            </Tooltip>
                                                                        </div>
                                                                        : (button.name === 'Baixar NFS') ?
                                                                            <div style={{ marginTop: "3px" }}>
                                                                                <Tooltip title={(lang === 'br' ? 'Baixar NFS' : 'Download NFS')} placement="top-start">
                                                                                    <Fab
                                                                                        color="primary"
                                                                                        aria-label="Baixar NFS"
                                                                                        disabled={button.disabled}
                                                                                        size="small"
                                                                                        type={form} f
                                                                                        onClick={async () => button.func()}
                                                                                    >
                                                                                        <CloudDownload />
                                                                                    </Fab>
                                                                                </Tooltip>
                                                                            </div>
                                                                            : (button.name === 'Ajuda') ?
                                                                                <div style={{ marginTop: "3px" }}>
                                                                                    <Tooltip title={(lang === 'br' ? 'Ajuda' : 'Help')} placement="top-start">
                                                                                        <Fab
                                                                                            color="primary"
                                                                                            aria-label={(lang === 'br' ? 'Ajuda' : 'Help')}
                                                                                            size="small"
                                                                                            type={form}
                                                                                            onClick={() => button.func()}
                                                                                        >
                                                                                            <HelpOutlineIcon />
                                                                                        </Fab>
                                                                                    </Tooltip>
                                                                                </div>
                                                                                : (button.name === 'Cancelar') ?
                                                                                    <div style={{ marginTop: "3px" }}>
                                                                                        <Tooltip title={(lang === 'br' ? 'Cancelar' : 'Cancel')} placement="top-start">
                                                                                            <Fab
                                                                                                color="primary"
                                                                                                aria-label={(lang === 'br' ? 'Cancelar' : 'Cancel')}
                                                                                                size="small"
                                                                                                type={form}
                                                                                                onClick={() => button.func()}
                                                                                            >
                                                                                                <DeleteIcon />
                                                                                            </Fab>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    : (button.name === 'Duplicar') ?
                                                                                        <div style={{ marginTop: "3px" }}>
                                                                                            <Tooltip title={(lang === 'br' ? 'Duplicar' : 'Duplicate')} placement="top-start">
                                                                                                <Fab
                                                                                                    color="primary"
                                                                                                    aria-label={(lang === 'br' ? 'Duplicar' : 'Duplicate')}
                                                                                                    size="small"
                                                                                                    type={form}
                                                                                                    onClick={() => button.func()}
                                                                                                >
                                                                                                    <FileCopyIcon />
                                                                                                </Fab>
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                        : // OUTROS BOTÕES
                                                                                        <div style={{ marginTop: "3px" }}>

                                                                                        </div>
                            }
                        </div>
                    )
                })
            }
        </div >
    )
}

export default Hpromainbuttons