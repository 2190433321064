let ipTeste = localStorage.getItem('TOUCHWEB_IP_PORTA_TESTE')

let _serverIPAddress = ''
if (ipTeste) {
	_serverIPAddress = ipTeste
} else {
	// ******** COLOCAR HTTPS ***********
	_serverIPAddress = 'www.carregamentos.com.br'
	// _serverIPAddress = 'localhost:8080' // LOCALHOST
	// _serverIPAddress = '192.168.0.45:8080' // IP
	// _serverIPAddress = '192.168.0.105:8080' // GRO
	// _serverIPAddress = '192.168.0.58:8080' // RCS
}

export const process = async (processID, parameters = {}, callback) => {

	if (!_serverIPAddress) {
		throw new Error('O endereço do IP do servidor não está configurado.');
	}

	parameters.process = processID;

	//const queryString = Object.keys(parameters).map(key => `${key}=${parameters[key]}`).join('&');
	const queryString = serializer(parameters);

	try {

		const response = await fetch(`https://${_serverIPAddress}/.process`,
			// const response = await fetch(`http://${_serverIPAddress}/.process`,
			{
				method: 'POST',
				body: queryString,
			}
		);

		return unescape(await response.text());

	} catch (error) {
		throw new Error(error);
	}
}

export const form = async (processID, fdata) => {

	if (!_serverIPAddress) {
		throw new Error('O endereço do IP do servidor não está configurado.');
	}

	fdata.append('process', processID)

	try {
		const response = await fetch(`https://${_serverIPAddress}/.form`,
			// const response = await fetch(`http://${_serverIPAddress}/.form`,
			{
				method: 'POST',
				body: fdata,
			}
		);

		return unescape(await response.text());

	} catch (error) {
		throw new Error(error);
	}
}

export function delayedAlert(title, message, buttons, options, type, delay = 510) {

	// Essa função é um workaround para o problema descrito na issue abaixo:
	// https://github.com/facebook/react-native/issues/10471

	setTimeout(() => {
		alert(title, message, buttons, options, type);
	}, delay);
}

export function applyMask(value, pattern) {
	let i = 0;
	let v = value.toString()
		.replace('!', '').replace('@', '').replace('#', '')
		.replace('$', '').replace('%', '').replace('¨', '')
		.replace('&', '').replace('*', '').replace('(', '')
		.replace(')', '').replace('-', '').replace('=', '')
		.replace('+', '').replace('×', '').replace('.', '')
		.replace('*', '').replace('/', '').replace('\\', '')
		.replace('.', '').replace(';', '').replace(',', '')
		.replace('<', '').replace('>', '').replace('[', '')
		.replace('[', '').replace('´', '').replace('`', '')
		.replace('º', '').replace('~', '').replace('?', '')
		.replace('|', '').replace(' ', '').replace('/', '');
	if (v.length === 0) {
		return ''
	}
	let lenMask = value.length
	let nX = 0
	let cX = pattern.slice(lenMask - 1, lenMask)
	if (cX !== '#') {
		nX = 1
		if (window.event.inputType === 'deleteContentBackward') {
			nX = 0
		}
		if (window.event.inputType === 'deleteContentForward') {
			nX = 0
		}
	}
	pattern = pattern.slice(0, lenMask + nX)

	return pattern.replace(/#/g, _ => v[i] ? v[i++] : '');
}

//value é o valor
//precision é o numero de casas depois da virgula
export function applyMaskValue(value, precision) {
	if ((value - Math.trunc(value)) !== 0) {
		let centavos = '.' + value.toString().slice(value.length - precision, value.length)
		value = value.toString().slice(0, value.length - (precision + 1))
			.replace('.', '').replace(',', '.')
			.replace('R', '').replace('$', '')
		return (parseFloat(value.replace('.', '') + centavos)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: precision });
	} else {
		value = value.toString().slice(0, value.length)
			.replace('.', '').replace(',', '.')
			.replace('R', '').replace('$', '')
		return (parseFloat(value)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: precision });
	}
}

export function serializer(obj) {
	let str = []
	for (let key in obj) {
		if (obj.hasOwnProperty(key)) {
			str.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
		}
	}
	return str.join('&')
}
