import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { Field, getIn } from 'formik'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { Divider, Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

export const MaterialViewFormik = ({
    field,
    form,
    disabled,
    showMessage,
    helperText,
    onChange,
    onExit,
    manually,
    labeltext,
    icon,
    viewname,
    infoArray,
    ...props
}) => {
    const [showMenu, setShowMenu] = useState(false)
    const [filteredArray, setfilteredArray] = useState(infoArray)
    const [dheight, setDheight] = useState(-1)

    const { name, value, label, top, onBlur, ...otherFieldProps } = field
    const { touched, errors, isSubmitting, setFieldValue } = form


    useEffect(() => {
        setfilteredArray(infoArray);
    }, [infoArray])

    const useStyles = makeStyles(theme => ({
        menuDiv: {
            borderStyle: 'solid',
            borderWidth: 2,
            borderTopWidth: 0,
            borderColor: theme.palette.primary.main,
            borderRadius: 2,
            padding: 5,
            height: dheight,
            maxHeight: 350,
            overflow: 'auto',
            transition: '0.5s',
        },
        wrapperDiv: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: 10,
            marginBottom: 10,
        },
        aItem: {
            display: 'flex',
            flexDirection: 'column',
            height: 50,
        },
        titleClass: {
            color: '#000000',
            fontSize: 18,
            fontWeight: 'bold',
            marginLeft: 10,
        },
        subtitleClass: {
            color: '#000000',
            fontSize: 12,
            marginLeft: 10,
        },
    }))

    const classes = useStyles()

    const fieldError = getIn(errors, name)
    const showError = getIn(touched, name) && !!fieldError

    const filterArray = (newValue, viewname) => {
        const newValueUpper = newValue.toUpperCase()
        let aArr = infoArray

        if (newValueUpper) {
            if (viewname === "VFAV") {
                aArr = aArr.filter(function (item) {
                    let x = item.codaux.toUpperCase().indexOf(newValueUpper)
                    let y = item.cpf.toUpperCase().indexOf(newValueUpper)
                    let z = item.nom.toUpperCase().indexOf(newValueUpper)
                    return x > -1 || y > -1 || z > -1
                })
            } else if (viewname === "VBAN") {
                aArr = aArr.filter(function (item) {
                    let x = item.cod.toUpperCase().indexOf(newValueUpper)
                    let y = item.nom.toUpperCase().indexOf(newValueUpper)
                    return x > -1 || y > -1
                })
            }
        }
        setfilteredArray(aArr)
    }

    const handleChange = e => {
        const newValue = e.target.value

        filterArray(newValue, viewname)

        if (onChange && typeof onChange === 'function') {
            onChange(newValue)
        }
        if (!manually) {
            setFieldValue(name, newValue, false)
        }
    }

    const handleBlur = e => {
        let newValue = e.target.value
        // if (newValue.length !== 0) {
        //     _toggleMatFunction(true)
        // }
        onBlur(e)
        setDheight(0)
        setShowMenu(false)
        if (onExit && typeof onExit === 'function') {
            onExit(newValue)
        }
    }

    const handleFocus = () => {
        setShowMenu(true)
        setDheight(250)
    }

    const suggestionsListComponent = (
        <div className={classes.menuDiv}>
            {filteredArray.map((item, index) => {
                return (
                    <div key={index}>
                        {
                            (viewname === "VFAV") ?
                                <>
                                    <div 
                                        className={classes.aItem}
                                        onMouseDown={() => setFieldValue(name, `${item.codaux} - ${item.cpf} - ${item.nom}`, false)}
                                    >
                                        <Typography key={index + 1} variant="caption" className={classes.titleClass}>{item.codaux} - {item.cpf} - {item.nom}</Typography>
                                    </div>
                                    <Divider key={index + 2} />
                                </>
                            : (viewname === "VBAN") ?
                                <>
                                    <div 
                                        className={classes.aItem}
                                        onMouseDown={() => setFieldValue(name, `${item.cod} - ${item.nom}`, false)}
                                    >
                                        <Typography key={index + 1} variant="caption" className={classes.titleClass}>{item.cod} - {item.nom}</Typography>
                                    </div>
                                    <Divider key={index + 2} />
                                </>
                            :
                            null
                        }
                    </div>
                )
            })}
        </div>
    );

    return (
        <div 
            className={classes.wrapperDiv}            
        >
            <FormControl
                fullWidth
                variant="outlined"
                size="small"
            >
                <InputLabel htmlFor={name}>{labeltext}</InputLabel>
                <OutlinedInput
                    {...props}
                    {...field}
                    {...otherFieldProps}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    name={name}
                    value={value === null ? '' : value}
                    disabled={disabled !== undefined ? disabled : isSubmitting}
                    error={showError}
                    helpertext={showMessage ? (showError ? fieldError : helperText) : undefined}
                    margin={"dense"}
                    color="primary"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Consulta"
                                onClick={() => console.log('ip')}
                                onMouseDown={() => console.log('mousedown')}
                                edge="end"
                                size="small"
                                disabled={disabled !== undefined ? disabled : isSubmitting}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            {showMenu ?
                suggestionsListComponent :
                null
            }
        </div>
    )
}

MaterialViewFormik.defaultProps = {
    showMessage: false,
}

MaterialViewFormik.propTypes = {
    disabled: PropTypes.bool,
    showMessage: PropTypes.bool,
    helperText: PropTypes.string,
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func,
}

const ViewFormik = props => <Field {...props} component={MaterialViewFormik} />

ViewFormik.propTypes = {
    name: PropTypes.string.isRequired
}

export default ViewFormik