import React, { useState } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    //Redirect
} from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'

//import { process } from './components/HproUtils'

// Páginas do projeto
import LoginScreen from './pages/Login/index'
import Main from './pages/Main'
import MainMenu from './pages/MainMenu'
import TrocarSenha from './pages/TrocarSenha'
import CarregManut from './pages/Carregamentos'
import ConsultaCarreg from './pages/Consultas'
import DetalhaCarreg from './pages/Consultas/DetalhaCarreg.js'

import './global.css'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const App = () => {

    const [openroutererror, setOpenroutererror] = useState(false)

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: "#00a099",
                contrastText: '#fff',
            },
            secondary: {
                main: "#2f2f2f",
                contrastText: '#000',
            },
            //text: {
            //    primary: '#2f2f2f',
            //    secondary: '#fff'
            //}
        },
    });

    /*
    const isAllowed = (x) => {
        return true
    }
    
    const AuthRoute = ({ component: Component, ...rest }) => (
        <Route
            {...rest}
            render={props =>
                (!localStorage.getItem('UserName')) ? (
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                ) : (
                        isAllowed({ ...props.location }) ? (
                            <Component {...props} />
                        ) : (
                            <Redirect to={{ pathname: '/main', state: { from: props.location } }} />
                        )
                    )
            }
        />
    )
    */

    const handleCloseWarning = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenroutererror(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <Route path="/" exact component={LoginScreen} />
                    <Main>
                        <Switch>
                            <Route path="/main" exact component={MainMenu} />
                            <Route path="/chgpassword" exact component={TrocarSenha} />
                            <Route path="/carregmanut/:cli/:pro" exact component={CarregManut} />
                            <Route path="/consultacarreg/:cli" exact component={ConsultaCarreg} />
                            <Route path="/detalhacarreg/:num" exact component={DetalhaCarreg} />
                        </Switch>
                        <div>
                            <Snackbar open={openroutererror} autoHideDuration={6000} onClose={handleCloseWarning}>
                                <Alert onClose={handleCloseWarning} severity="error">
                                    Acesso não liberado
                                </Alert>
                            </Snackbar>
                        </div>
                    </Main>
                </Switch>
            </Router>
        </ThemeProvider>
    );
}

export default App;
