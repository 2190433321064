import React from 'react'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Fade, Fab, Tooltip, Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import ViewFormik from '../../formikComponents/ViewFormik'
import InputFormik from '../../formikComponents/InputFormik'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { process, delayedAlert } from '../../components/HproUtils'

import NumberFormat from 'react-number-format'

import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

const initialValues = {
    tip: 'Interno',
    nom: '',
    cpf: '',
    val: '0,00',
    idp: '',
    car: '',
    nca: 'Não',
    ban: '',
    age: '',
    dag: '',
    nco: '',
    dco: '',
    cpo: 'Não',
    obs: '',
}

const validationSchema = Yup.object().shape({
    //cpf: Yup.string().required("Campo obrigatório"),
    nom: Yup.string().required("Campo obrigatório")
})


const WebFav01 = ({ open, setOpen, numero, banco, bancos, itens, setLoading, setMsg, setOpenMsg, getCarregamento }) => {

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalRoot: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '7px',
            boxShadow: theme.shadows[5],
            //padding: theme.spacing(2, 4, 3),
            maxHeight: '70%',
            minWidth: 1000,            
            flex: (midea680) ? 1 : 0,
            display: 'flex',
            flexDirection: 'column',
        },      
        inputPanel: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
        }
    }))

    function NumberFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: (values.value > 9999999.99) ? 9999999.99 : values.value,
                        },
                    });
                }}
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={2}
                isNumericString
                prefix="R$ "
            />
        )
    }    
    
    const classes = useStyles()
    const midea680 = useMediaQuery('(max-width:680px)')

    const submitForm = async (values) => {
        setLoading(true)
        try {

            const params = {
                usu: localStorage.getItem('TouchWeb_UserName'),
                tip: values.tip,
                nom: values.nom,
                cpf: values.cpf,
                val: values.val,
                idp: values.idp,
                car: values.car,
                nca: values.nca,
                ban: values.ban,
                age: values.age,
                dag: values.dag,
                nco: values.nco,
                dco: values.dco,
                cpo: values.cpo,
                obs: values.obs,
                num: numero,
            }

            const processResponse = await process('INCLUIFAVORECIDO', params ) 
            const processResponseObj = JSON.parse(processResponse)
            const success = processResponseObj.success
            const content = processResponseObj.content

            if (success) {
                getCarregamento()
                setOpen(false)
            } else {
                setMsg(content.message)                
                setOpenMsg(true)
                setLoading(false)
            }

        } catch (error) {
            delayedAlert('Error', error.message)
            setLoading(false)
        }
    }

    return (
        <Modal
            style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            open={open}
            onClose={() => setOpen(false)}
        >   
            <Fade in={open}>
                <div className={classes.modalRoot}>
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#00a099', borderRadius: 7 }}>
                        <Typography variant="h5" style={{ color: 'white', alignSelf: 'center', marginTop: 4, marginBottom: 4 }}>Inclusão de favorecido - Carregamento {(numero) && `${numero.substring(0,2)}/${numero.substring(2,8)}`}</Typography>   
                    </div>
                    <div style={{ flex: 1, overflowY: 'scroll' }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => submitForm(values)}
                    >
                        {({ handleSubmit, handleChange, handleBlur, values, errors, setFieldValue }) => (
                            <form onSubmit={handleSubmit} style={{ margin: 15, flex: 1 }}>
                                
                                    <InputFormik
                                        fullWidth
                                        name="tip"
                                        label="Tipo"
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        select
                                        aOptions={['Interno','Externo']}
                                        onChange={() => {
                                            setFieldValue('nom', '')
                                            setFieldValue('cpf', '')
                                        }}
                                    />
                                    <div className={classes.inputPanel}>
                                        {
                                            (values.tip === 'Interno') ?
                                                <ViewFormik
                                                    required
                                                    id="nom"
                                                    name="nom"
                                                    label="Favorecido"
                                                    labeltext="Favorecido"
                                                    margin="normal"
                                                    viewname="VFAV"
                                                    infoArray={itens}
                                                />
                                            :
                                                <>
                                                    <InputFormik
                                                        style={{ flex: 1, marginRight: 20 }}
                                                        required
                                                        name="cpf"
                                                        label="CPF"
                                                        variant="outlined"
                                                        margin="normal"
                                                        type="text"  
                                                        max={14}                                      
                                                    />
                                                    <InputFormik
                                                        style={{ flex: 3 }}
                                                        required
                                                        name="nom"
                                                        label="Nome"
                                                        variant="outlined"
                                                        margin="normal"
                                                        type="text"
                                                        max={60}
                                                    />
                                                </>
                                        }
                                    </div>
                                    <div className={classes.inputPanel}>
                                        <InputFormik
                                            style={{ flex: 1, marginRight: 20 }}
                                            manually={true}
                                            onChange={(e) => setFieldValue('val', e)}
                                            name="val"
                                            label="Valor"
                                            variant="outlined"
                                            margin="normal"
                                            type="text"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                        />
                                        <InputFormik
                                            style={{ flex: 3 }}
                                            name="idp"
                                            label="ID/Proxy"
                                            variant="outlined"
                                            margin="normal"
                                            type="text"
                                            max={40}
                                        />
                                    </div>
                                    {
                                        (!banco) ?
                                            <div className={classes.inputPanel}>
                                                <InputFormik
                                                    style={{ flex: 3, marginRight: 20 }}
                                                    name="car"
                                                    label="Cartão"
                                                    variant="outlined"
                                                    margin="normal"
                                                    type="text"
                                                    max={30}
                                                />
                                                <InputFormik
                                                    style={{ flex: 1 }}
                                                    name="nca"
                                                    label="Novo cartão"
                                                    variant="outlined"
                                                    margin="normal"
                                                    type="text"
                                                    select
                                                    aOptions={['Sim','Não']}
                                                />
                                            </div>
                                        :
                                            <>
                                                <ViewFormik
                                                    required
                                                    id="ban"
                                                    name="ban"
                                                    label="Banco"
                                                    labeltext="Banco"
                                                    margin="normal"
                                                    viewname="VBAN"
                                                    infoArray={bancos}
                                                />
                                                <div className={classes.inputPanel}>
                                                    <InputFormik
                                                        style={{ flex: 2, marginRight: 20 }}
                                                        name="age"
                                                        label="Agência"
                                                        variant="outlined"
                                                        margin="normal"
                                                        type="text"
                                                        max={4}
                                                        required
                                                    />
                                                    <InputFormik
                                                        style={{ flex: 1, marginRight: 20 }}
                                                        name="dag"
                                                        label="Dígito"
                                                        variant="outlined"
                                                        margin="normal"
                                                        type="text"
                                                        max={1}
                                                    />
                                                    <InputFormik
                                                        style={{ flex: 2, marginRight: 20 }}
                                                        name="nco"
                                                        label="Nº Conta"
                                                        variant="outlined"
                                                        margin="normal"
                                                        type="text"
                                                        max={15}
                                                        required
                                                    />
                                                    <InputFormik
                                                        style={{ flex: 1, marginRight: 20 }}
                                                        name="dco"
                                                        label="Dígito"
                                                        variant="outlined"
                                                        margin="normal"
                                                        type="text"
                                                        max={1}
                                                        required
                                                    />
                                                    <InputFormik
                                                        style={{ flex: 1 }}
                                                        name="cpo"
                                                        label="Conta poupança"
                                                        variant="outlined"
                                                        margin="normal"
                                                        type="text"
                                                        select
                                                        aOptions={['Sim','Não']}
                                                    />
                                                </div>
                                            </>
                                    }
                                    <InputFormik
                                        fullWidth
                                        name="obs"
                                        label="Observações"
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        multiline={true}
                                        rows={8}
                                    />
                                <div>
                                    <Tooltip style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} title={'Gravar'} placement="top-start">
                                        <Fab
                                            color="primary"
                                            aria-label="Gravar"
                                            size="small"
                                            type="submit"
                                        >
                                            <CheckIcon />
                                        </Fab>
                                    </Tooltip>
                                    <Tooltip style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} title={'Fechar'} placement="top-start">
                                        <Fab
                                            color="primary"
                                            aria-label="Fechar"
                                            size="small"
                                            type="button"
                                            onClick={() => setOpen(false)}
                                        >
                                            <CloseIcon />
                                        </Fab>
                                    </Tooltip>
                                </div>
                            </form>
                        )}
                    </Formik>   
                    </div>
                </div>                    
            </Fade>      
        </Modal>
    )
}

export default WebFav01
